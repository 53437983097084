import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { IconButton, LinearProgress } from '@mui/material';
import { getSignedUploadURl, getSignedURltoviewFile } from 'src/services/file';
import Lightbox, { useLightBox } from 'src/components/lightbox';
import axios from 'axios';
import RejectionFiles from './errors-rejection-files';
import Iconify from '../iconify';
import Image from '../image/image';

export default function UploadAvatar({
  disabled,
  label,
  helperText,
  sx,
  path,
  setValue,
  viewmode,
  ...other
}) {
  const [file, setFile] = useState([]);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(0);
  const [filesWithUrl, setFilesWithUrl] = useState([]);
  const [openLightBox, setOpenLightBox] = useState(false);
  const [index, setIndex] = useState(0);
  const lightbox = useLightBox([]);

  useEffect(() => {
    setError(other.error);
    setFile(other?.value || []);
  }, [other.error, other.value]);

  const handleUpload = async (File) => {
    if (!File) {
      alert('Please select a file to upload');
      return;
    }
    var fileTemp = await file.filter(item => item !== "");

    try {
      // Replace 'your-backend-api-endpoint' with the actual endpoint to get the signed AWS URL
      const urlIncoded = File?.name?.split(" ").join("");
      const response = await getSignedUploadURl(`${path}/${urlIncoded}`);

      if (response) {
        const { signedUrl } = response;

        const formData = new FormData();
        formData.append('file', File);

        const uploadResponse = await axios.put(signedUrl, File, {
          headers: {
            'Content-Type': 'application/octet-stream',
          },

          onUploadProgress: (event) => {
            const percentCompleted = (event.loaded / event.total) * 100;
            setProgress(percentCompleted);
          },
        });

        if (uploadResponse.status === 200) {
          setProgress(0);
          // file.map
          let allFiles = [...fileTemp];
          allFiles.push(`${path}/${urlIncoded}`);

          setFile(allFiles);
          setValue && setValue(allFiles);
        } else {
          alert('Failed to upload file');
        }
      } else {
        alert('Failed to get signed URL');
      }
    } catch (er) {
      setProgress(0);
      setError('Error:', error);
      // console.log(er);
      alert('Failed to communicate with the server');
    }
  };

  const onDrop = async (acceptedFiles) => {
    const selectedFile = acceptedFiles[0];
    let allFiles = [...file];
    // allFiles.push(selectedFile);

    setFile(allFiles);
    handleUpload(selectedFile);
  };

  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: false,
    disabled: progress > 0,
    // accept: {
    //   'image/*': [],
    // },
    onDrop,
  });

  useEffect(() => {
    Promise.all(file?.map((f) => f ? getSignedURltoviewFile(f):null)).then((fi) => {
      setFilesWithUrl(fi);
    });
  }, [file]);

  return (
    <Box>
      {!viewmode ? (
        <Button
          {...getRootProps()}
          variant="outlined"
          fullWidth
          disabled={disabled}
          style={{ textTransform: 'uppercase' }}
        >
          <input {...getInputProps()} accept="image/*" />
          {`${label}`}
        </Button>
      ) : (
        ''
      )}
      {filesWithUrl.map((i, index) => (
        <Stack alignItems="baseline" direction="row" spacing={1}>
          <Stack direction="column" sx={sx}>
            <Image
              sx={{ width: 40, height: 40, borderRadius: 1 }}
              ratio="1/1"
              onClick={() => {
                setOpenLightBox(true);
                setIndex(index);
              }}
              src={i}
            />
            {/* <Button style={{ textTransform: 'uppercase' }} variant="outlined">
              View {label}
            </Button>
            <Typography variant="body" style={{ textDecoration: 'underline', cursor: 'pointer' }}>
              {hasFile && i ? (i?.name ? i?.name : fileNameByUrl(i)) : ''}
            </Typography> */}
          </Stack>
          {!disabled ? (
            <IconButton
              style={{ height: '40px', width: '40px' }}
              onClick={() => {
                setFile(
                  file.filter((f) => {
                    return !i.includes(f);
                  })
                );
                setValue(file.filter((f) => !i.includes(f)));
              }}
            >
              <Iconify icon="material-symbols:close" sx={{ height: 32 }} />
            </IconButton>
          ) : (
            ''
          )}
        </Stack>
      ))}

      {progress > 0 ? <LinearProgress variant="determinate" value={progress} /> : ''}
      {error ? (
        <Typography
          textTransform="uppercase"
          variant="caption"
          sx={{ color: 'error.main', pl: 2, pt: 1 }}
        >
          {error}
        </Typography>
      ) : (
        ''
      )}
      <RejectionFiles fileRejections={fileRejections} />
      <Lightbox
        open={openLightBox}
        close={() => setOpenLightBox(false)}
        slides={filesWithUrl.map((i) => {
          return {
            src: i,
            type: 'image',
          };
        })}
        index={index}
        // disabledZoom={state.disabledZoom}
        // disabledTotal={state.disabledTotal}
        // disabledVideo={state.disabledVideo}
        // disabledCaptions={state.disabledCaptions}
        // disabledSlideshow={state.disabledSlideshow}
        // disabledThumbnails={state.disabledThumbnails}
        // disabledFullscreen={state.disabledFullscreen}
      />
    </Box>
  );
}

UploadAvatar.propTypes = {
  disabled: PropTypes.object,
  error: PropTypes.bool,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  helperText: PropTypes.object,
  sx: PropTypes.object,
};
