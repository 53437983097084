import PropTypes from 'prop-types';
import { memo, useEffect, useState, useRef } from 'react';
// @mui
import Stack from '@mui/material/Stack';
// theme
import { hideScroll } from 'src/theme/css';
//
import Iconify from 'src/components/iconify';
import { useAuthContext } from 'src/auth/hooks';
import { navHorizontalConfig } from '../config';
import NavList from './nav-list';

import { InputBase, Box, InputAdornment, Paper, List, ListItem, ListItemText, Tab, TableBody, TableRow, TableCell, CircularProgress, Typography, TableHead, Table, TableContainer } from '@mui/material';
import { Close, Search } from '@mui/icons-material';
import { useDebounce } from 'src/hooks/use-debounce';
import { elasticSearchApi } from 'src/services/quotes';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Scrollbar from 'src/components/scrollbar';
import { primaryPresets } from 'src/theme/options/presets';
import { useRouter } from 'src/routes/hooks';
import { HasAccess, usePermify } from '@permify/react-role';
import SearchHighlight from '../searchHighlight';
import CircleIcon from '@mui/icons-material/Circle';

// ----------------------------------------------------------------------

function NavSectionHorizontal({ data, config, sx, ...other }) {
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState({});
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [isListOpen, setIsListOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState('quote');
  const suggestionListRef = useRef(null);
  const router = useRouter();
  const { user, currency } = useAuthContext();
  // console.log(user, currency,'user')

  function handleInputKeyUp() {
    clearTimeout(typingTimeout);

    setTypingTimeout(setTimeout(HandleSearch, 1000));
  }

  useEffect(() => {
    // Add event listener to handle clicks outside of the suggestion list
    const handleClickOutside = (event) => {
      if (suggestionListRef.current && !suggestionListRef.current.contains(event.target)) {
        setIsListOpen(false);
      }
    };  

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const HandleSearch = () => {
    setLoading(true);
    if (searchText) {
      elasticSearchApi(searchText)
        .then((response) => {
          setResult(response);
          setLoading(false);
          setIsListOpen(true);
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      setResult({});
    }
  };

  const handleChange = (newValue) => {
    setCurrentTab(newValue);
  };

  function calculateDateRange(startingDate, numberOfDays) {
    return `${Math.ceil(numberOfDays / 7) > 0 ? `${Math.ceil(numberOfDays / 7)} week` : '- -'}`;
  }

  const shouldHighlightTab = (dataLength) => dataLength > 0;
  const QUOTECOL = [
    {
      id: 'projectId',
      label: 'PROJECT ID',
      render: (value, row) => {
        return (
          <Box display="flex" alignItems="center">
            {
              row?.isarchived == "true" ? <CircleIcon sx={{ color:"red", fontSize:"small", marginRight:1}} /> 
              :  row?.isarchived == true ? <CircleIcon sx={{ color:"red", fontSize:"small", marginRight:1}} /> 
              :  row?.isarchived == "false" ? <CircleIcon sx={{ color:"#19a81e", fontSize:"small", marginRight:1}}/> 
              :  row?.isarchived == false ? <CircleIcon sx={{ color:"#19a81e", fontSize:"small", marginRight:1}}/> 
              : ""
            }
            {"   "}
            <SearchHighlight searchText={searchText} searchResultText={value}/>
          </Box>
        );
      },
    },
    {
      id: 'RFQ',
      label: 'RFQ',
    },
    {
      id: 'RFQName',
      label: 'RFQ NAME',
    },
    {
      id: 'RFQCreatedAt',
      label: 'RFQ DATE',
      render: (value, row) => {
        let formattedDate;
        if (value) {
          const dateObject = new Date(value);
          formattedDate = dateObject.toLocaleDateString('en-GB');
        }
        return (
          <Box>
            <SearchHighlight searchText={searchText} searchResultText={value ? formattedDate : '---'}/>
          </Box>
        );
      },
    },
  ];

  const PO_ACC_COL = [
    {
      id: 'clientPO',
      label: 'PO NUMBER',
    },
  ];

  const PN_COL = [
    {
      id: 'PN',
      label: 'PN',
      render: (value, row) => (
        <Box>
          <SearchHighlight searchText={searchText} searchResultText={row?.line_items?.PN}/>
        </Box>
      ),
    },
    {
      id: 'DESCRIPTION',
      label: 'DESCRIPTION',
      render: (value, row) => (
        <Box>
          <SearchHighlight searchText={searchText} searchResultText={row?.line_items?.description}/>
        </Box>
      ),
    },
  ]

  const DELIVREY_COL = [
    {
      id: 'trackingNumber',
      label: 'TRACKING IDS',
    },
    {
      id: 'PN',
      label: 'TOTAL QUANTITY',
      render: (value, row) => (
        <Box>
          <SearchHighlight searchText={searchText} searchResultText={row?.qty? row?.qty[0]:'--'} />
        </Box>
      ),
    }
  ]



  const CommonTable = ({data,COLUMNS,tab}) => {
    const redirectHandler = (row) => {
      if (user?.role?.length ==1 && user?.role[0] === "client"){
        if(tab === 'po') {
          router.push(`/poStage/purchaseOrderClient/${row._id}`)
        }else if (tab === 'accounting') {
          router.push(`/frontend-acc-client/${row._id}`)
        }else if (tab === 'delivery') {
          router.push(`/frontendView/deliveryl2/${row.Quote_id}`)
        }else{
          router.push(`/search/${row._id}/${tab}`)
        }
      }else {
        if(tab === 'po') {
          router.push(`/poStage/purchaseOrderl1/${row._id}`)
        }else if (tab === 'accounting') {
          router.push(`/frontend-acc-client/${row._id}`)
        }else if (tab === 'delivery') {
          router.push(`/delivery/deliveryl2/${row.Quote_id}`)
        }else{
          router.push(`/quote/l1/${row._id}`)
        }
      }    
    }
    return (
      <Paper elevation={1}>
      <TableContainer sx={{ overflow: 'unset' }}>
        <Scrollbar sx={{ maxHeight: '50vh' }}>
          <Table stickyHeader sx={{ minWidth: 800 }}>
            <TableHead>
              <TableRow>
                {COLUMNS.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      textTransform: 'uppercase',
                      background: primaryPresets[10].light,
                      color: primaryPresets[11].main,
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      flexDirection="column"
                      alignItems="center"
                      width="100%"
                      height={220}
                    >
                      <CircularProgress />
                      <br />
                      <Typography textTransform="uppercase">Fetching...</Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {data?.map((row) => (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.code}
                    sx={{cursor:'pointer'}}
                    onClick={() => redirectHandler(row)}
                  >
                    {COLUMNS.map((column) => {
                      const value = row[column.id];

                      return (
                        <TableCell key={column.id} align={column.align}>
                          <Typography textTransform="uppercase">
                            {' '}
                            {column.render ? column.render(value, row) : 
                              <SearchHighlight searchText={searchText} searchResultText={value} />
                            }
                          </Typography>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </Scrollbar>
      </TableContainer>
    </Paper>
    )
  }

  return (
    // <Stack
    //   direction="row"
    //   display={'flex'}
    //   width={'100%'}
    //   my={0}
    //   height={'100%'}
    //   justifyContent={'space-between'}
    //   // sx={{
    //   //   ...hideScroll.y,
    //   //   ...sx,
    //   // }}
    //   {...other}
    // >
    <>
      {data.map((group, index) => (
        <Group
          key={group.subheader || index}
          items={group.items}
          config={navHorizontalConfig(config)}
        />
      ))}
      <Box width={'100%'} height={'100%'}></Box>
      {user?.permissions?.length == 1 && user?.permissions[0] === "field_team_level4_view" && user?.role?.length ==1 && user?.role[0] === "field_team" ? null :
      <Box width={'100%'}
        sx={{ borderLeft: 1, borderColor: 'white', height:"50px", display: 'flex',
          alignItems: 'center', justifyContent:"center"}}
      >
        <InputBase
          endAdornment={
            <InputAdornment position="end">
              <Search sx={{ color: 'white' }} />
            </InputAdornment>
          }
          placeholder="SEARCH"
          fullWidth
          onChange={(e) => setSearchText(e.target.value)}
          onKeyUp={() => {
            handleInputKeyUp();
          }}
          sx={{
            color: 'white',
            backgroundColor: '#415261',
            px: 2,
            height:"50px",
            opacity:1,
            '&::placeholder': {
            color: 'red',
            opacity: 1, // otherwise firefox shows a lighter color
          }
          }}
          
        />
        {result && isListOpen && (
          <Paper
            elevation={3}
            ref={suggestionListRef}
            sx={{
              position: 'absolute',
              top: '100%',
              right: 0,
              width: '50%',
              boxShadow: '0px 8px 16px rgba(23, 23, 23)',
            }}
          >
            <Box display="flex" justifyContent="space-between" sx={{mx:1}}>
              <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={currentTab}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList  aria-label="lab API tabs example" sx={{ color:'inherit' }}>
                    <Tab
                      label={`QUOTE (${result?.projectIdResults?.length})`}
                      value="quote"
                      onClick={() => handleChange('quote')}
                      sx={{
                        '&.Mui-selected': {
                          color:"inherit"
                        },
                        '&:not(.Mui-selected)': {
                          color:shouldHighlightTab(result?.projectIdResults?.length) ? 'red' : '#919EAB',
                          '&.css-1wzw0ra-MuiButtonBase-root-MuiTab-root': {
                            color:"inherit"
                          },
                        }
                      }}
                    />
                    <Tab
                      label={`PO (${result?.ClientPOResults?.length})`}
                      value="po"
                      onClick={() => handleChange('po')}
                      sx={{
                        '&.Mui-selected': {
                          color:"inherit"
                        },
                        '&:not(.Mui-selected)': {
                          color:shouldHighlightTab(result?.ClientPOResults?.length) ? 'red' : '#919EAB',
                          '&.css-1wzw0ra-MuiButtonBase-root-MuiTab-root': {
                            color:"inherit"
                          },
                        }
                      }}
                    />
                    <Tab
                      label={`ACCOUNTING (${result?.accountingResults?.length})`}
                      value="accounting"
                      onClick={() => handleChange('accounting')}
                      sx={{
                        '&.Mui-selected': {
                          color:"inherit"
                        },
                        '&:not(.Mui-selected)': {
                          color:shouldHighlightTab(result?.accountingResults?.length) ? 'red' : '#919EAB',
                          '&.css-1wzw0ra-MuiButtonBase-root-MuiTab-root': {
                            color:"inherit"
                          },
                        }
                      }}
                    />
                    <Tab
                      label={`DELIVERY (${result?.DeliveryTrackingResults?.length})`}
                      value="delivery"
                      onClick={() => handleChange('delivery')}
                      sx={{
                        '&.Mui-selected': {
                          color:"inherit"
                        },
                        '&:not(.Mui-selected)': {
                          color:shouldHighlightTab(result?.DeliveryTrackingResults?.length) ? 'red' : '#919EAB',
                          '&.css-1wzw0ra-MuiButtonBase-root-MuiTab-root': {
                            color:"inherit"
                          },
                        }
                      }}
                    />
                    <Tab
                      label={`RFQ (${result?.RFQResults?.length})`}
                      value="rfq"
                      onClick={() => handleChange('rfq')}
                      sx={{
                        '&.Mui-selected': {
                          color:"inherit"
                        },
                        '&:not(.Mui-selected)': {
                          color:shouldHighlightTab(result?.RFQResults?.length) ? 'red' : '#919EAB',
                          '&.css-1wzw0ra-MuiButtonBase-root-MuiTab-root': {
                            color:"inherit"
                          },
                        }
                      }}
                    />
                    <Tab
                      label={`PN (${result?.LineItemResults?.length})`}
                      value="pn"
                      onClick={() => handleChange('pn')}
                      sx={{
                        '&.Mui-selected': {
                          color:"inherit"
                        },
                        '&:not(.Mui-selected)': {
                          color:shouldHighlightTab(result?.LineItemResults?.length) ? 'red' : '#919EAB',
                          '&.css-1wzw0ra-MuiButtonBase-root-MuiTab-root': {
                            color:"inherit"
                          },
                        }
                      }}
                    />
                      {/* <Tab label={`QUOTE (${result?.projectIdResults?.length})`} value="quote" onClick={()=>handleChange('quote')}
                          sx={{
                                color: result?.projectIdResults?.length > 0 ? 'red' : 'inherit'
                              }}
                      />
                      <Tab label={`PO (${result?.ClientPOResults?.length})`} value="po" onClick={()=>handleChange('po')}/>
                      <Tab label={`ACCOUNTING (${result?.accountingResults?.length})`} value="accounting" onClick={()=>handleChange('accounting')}/>
                      <Tab label={`DELIVERY (${result?.DeliveryTrackingResults?.length})`} value="delivery" onClick={()=>handleChange('delivery')}/>
                      <Tab label={`RFQ (${result?.RFQResults?.length})`} value="rfq" onClick={()=>handleChange('rfq')}/>
                      <Tab label={`PN (${result?.LineItemResults?.length})`} value="pn" onClick={()=>handleChange('pn')}/> */}
                    </TabList>
                  </Box>
                  <TabPanel value="quote">{result?.projectIdResults && result?.projectIdResults[0] ? <CommonTable tab={currentTab} data={result?.projectIdResults} COLUMNS={QUOTECOL}/>: <Typography sx={{color:'red', textAlign:'center'}}>No data found</Typography>}</TabPanel>
                  <TabPanel value="po">{result?.ClientPOResults && result?.ClientPOResults[0] ? <CommonTable tab={currentTab} data={result?.ClientPOResults} COLUMNS={[...PO_ACC_COL,...QUOTECOL]}/>: <Typography sx={{color:'red', textAlign:'center'}}>No data found</Typography>}</TabPanel>
                  <TabPanel value="accounting">{result?.accountingResults && result?.accountingResults[0] ? <CommonTable tab={currentTab} data={result?.accountingResults} COLUMNS={[...QUOTECOL,...PO_ACC_COL]}/>: <Typography sx={{color:'red', textAlign:'center'}}>No data found</Typography>}</TabPanel>
                  <TabPanel value="delivery">{result?.DeliveryTrackingResults && result?.DeliveryTrackingResults[0] ? <CommonTable tab={currentTab} data={result?.DeliveryTrackingResults} COLUMNS={[...DELIVREY_COL,...QUOTECOL]}/>: <Typography sx={{color:'red', textAlign:'center'}}>No data found</Typography>}</TabPanel>
                  <TabPanel value="rfq">{result?.RFQResults && result?.RFQResults[0] ? <CommonTable tab={currentTab} data={result?.RFQResults} COLUMNS={QUOTECOL}/>: <Typography sx={{color:'red', textAlign:'center'}}>No data found</Typography>}</TabPanel>
                  <TabPanel value="pn">{result?.LineItemResults && result?.LineItemResults[0] ? <CommonTable tab={currentTab} data={result?.LineItemResults} COLUMNS={[...PN_COL,...QUOTECOL]}/>: <Typography sx={{color:'red', textAlign:'center'}}>No data found</Typography>}</TabPanel>
                </TabContext>
              </Box>
              <Close onClick={() => setIsListOpen(false)} sx={{mt:2,cursor: 'pointer'}} />
            </Box>
          </Paper>
        )}
      </Box>}
    {/* </Stack> */}
    </>
  );
}

NavSectionHorizontal.propTypes = {
  config: PropTypes.object,
  data: PropTypes.array,
  sx: PropTypes.object,
};

export default memo(NavSectionHorizontal);

// ----------------------------------------------------------------------

function Group({ items, config }) {
  const { isAuthorized, isLoading } = usePermify();
  const [backendAuth, setBackendAuth] = useState(false);
  useEffect(() => {
    const callAuth = async() => {
      let tempAuth = await isAuthorized([],"backend");
      await setBackendAuth(tempAuth)
    }
  callAuth();
  }, [])
  
 
  return (
    <>
      {items.map((list) => (
        <Box sx={{ borderRight: 1, borderColor: 'white', height:"50px", display: 'flex',
          alignItems: 'center', justifyContent:"center", m:0, p:0}}>
        <NavList
          key={list.title + list.path}
          data={list}
          depth={1}
          hasChild={!!list.children}
          config={config}
        />
        </Box>
      ))}
{backendAuth === false ? <>
 
  <Box sx={{ borderRight: 1, borderColor: 'white', height:"50px", display: 'flex',
          alignItems: 'center', justifyContent:"center", m:0, p:0}}>
        <NavList
          key={'DELIVERY ' + '/home/clientdelivery'}
          data={{
            title: 'DELIVERY',
            path: '/home/clientdelivery',
            module: 'delivery',
            icon: <Iconify icon={'la:shipping-fast'} />,
          }}
          depth={1}
          hasChild={false}
          config={config}
        />
        </Box>
      <HasAccess roles={['admin']} permissions={['delivery_l1']} >
      <Box sx={{ borderRight: 1, borderColor: 'white', height:"50px", display: 'flex',
          alignItems: 'center', justifyContent:"center", m:0, p:0}}>
        <NavList
          key={'DELIVERY BACKEND' + '/home/delivery'}
          data={{
            title: 'DELIVERY BACKEND',
            path: '/home/delivery',
            module: 'delivery',
            icon: <Iconify icon={'la:shipping-fast'} />,
          }}
          depth={1}
          hasChild={false}
          config={config}
        />
        </Box>
        </HasAccess>
        </> :null}
        <HasAccess roles={['admin']} permissions={['admin_manage_view']} >
      <Box sx={{ borderRight: 1, borderColor: 'white', height:"50px", display: 'flex',
          alignItems: 'center', justifyContent:"center", m:0, p:0}}>
        <NavList
          key={'ADMIN' + '/admin'}
          data={{
            title: 'ADMIN',
            path: '/admin',
            module: 'admin',
            icon: <Iconify icon={'solar:user-linear'} />,
          }}
          depth={1}
          hasChild={false}
          config={config}
        />
        </Box>
      </HasAccess>
    </>
  );
}

Group.propTypes = {
  config: PropTypes.object,
  items: PropTypes.array,
};
