import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import Iconify from 'src/components/iconify/iconify';
import Scrollbar from 'src/components/scrollbar';
import { TablePaginationCustom, useTable } from 'src/components/table';

function createData(checkBox, projectId, rfqNo, purchaseOrder, partNumber, qty, taxInvoice) {
  return {
    checkBox,
    projectId,
    rfqNo,
    purchaseOrder,
    partNumber,
    qty,
    taxInvoice,
  };
}

export default function CommercialInvoiceLevel2Dialog(props) {
  const table = useTable({ defaultRowsPerPage: 10 });
  const { open, handleClose } = props || {};

  const COLUMNS = [
    {
      id: 'checkBox',
      label: <Iconify width={20} icon="carbon:checkbox" />,
      minWidth: 50,
      render: () => <Iconify width={20} icon="carbon:checkbox" />,
    },
    {
      id: 'projectId',
      label: 'PROJECT ID',
      minWidth: 50,
    },
    {
      id: 'rfqNo',
      label: 'RFQ No. ',
      minWidth: 50,
    },
    {
      id: 'purchaseOrder',
      label: 'Purchase Order',
      minWidth: 50,
    },
    {
      id: 'partNumber',
      label: 'PART Number',
      minWidth: 50,
    },
    {
      id: 'qty',
      label: 'Quantity',
      minWidth: 50,
    },
    {
      id: 'taxInvoice',
      label: 'Tax Invoice',
      minWidth: 70,
      render: () => (
        <Box display="flex" flexDirection="column">
          <Button style={{ textTransform: 'uppercase' }} sx={{ width: 250 }} variant="contained">
            <Typography textTransform="uppercase" variant="button">Generate with Part Number</Typography>
          </Button>
          <Button style={{ textTransform: 'uppercase' }} component="label" variant="contained" sx={{ mt: 1.8, width: 250 }}>
            <Typography textTransform="uppercase" variant="button">Generate without Part Number</Typography>
          </Button>
        </Box>
      ),
    },
  ];

  const TABLE_DATA = [
    createData('', 1024, 'ABV12', 154513, 'BVT AD 24 918402', 2, ''),
    createData('', 1024, 'ABV12', 154513, 'BVT AD 24 918402', 2, ''),
    createData('', 1024, 'ABV12', 154513, 'BVT AD 24 918402', 2, ''),
    createData('', 1024, 'ABV12', 154513, 'BVT AD 24 918402', 2, ''),
  ];

  return (
    <Dialog maxWidth="xl" fullWidth open={open} onClose={() => handleClose(false)}>
      <DialogTitle variant="h4" style={{ textDecoration: 'underline' }} alignSelf="center">
        COMMERCIAL INVOICE
      </DialogTitle>
      <DialogContent>
        <Paper>
          <TableContainer>
            <Scrollbar>
              <Table stickyHeader sx={{ minWidth: 800 }}>
                <TableHead>
                  <TableRow>
                    {COLUMNS.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, textTransform: 'uppercase', border: 1 }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {TABLE_DATA.slice(
                    table.page * table.rowsPerPage,
                    table.page * table.rowsPerPage + table.rowsPerPage
                  ).map((row) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                      {COLUMNS.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell style={{ verticalAlign: 'top',textTransform:"uppercase"  }} key={column.id}>
                            {column.render ? column.render(value, row) : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={TABLE_DATA.length}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
          />
        </Paper>
      </DialogContent>
    </Dialog>
  );
}
