/* eslint-disable jsx-a11y/label-has-associated-control */
import { useSnackbar } from 'src/components/snackbar';
import {
  Box,
  Button,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useState, useEffect } from 'react';
import CommentDialog from 'src/components/comment-dialog';
import Iconify from 'src/components/iconify/iconify';
import Scrollbar from 'src/components/scrollbar';
import { TablePaginationCustom, useTable } from 'src/components/table';
import VIewFile from 'src/components/upload/ViewFIle';
import { updateInitiateDelivery } from 'src/services/delivery';
import { primaryPresets } from 'src/theme/options/presets';
import { fCurrency } from 'src/utils/format-number';
import { calculateClientUnitPrice } from 'src/utils/quoteHelperFunc';
import CommentsModal from 'src/components/comments/index';
import CountComment from 'src/components/comments/CountComment';
import { useNavigate, useParams } from 'react-router-dom';
import { HasAccess, usePermify } from '@permify/react-role';


export default function BackendAccVendorLevel1Table({ data, projectData }) {
  const { enqueueSnackbar } = useSnackbar({ autoHideDuration: 3000 });
  const table = useTable({ defaultRowsPerPage: 10000 });
  const { isAuthorized, isLoading } = usePermify();
  const [backendAuth, setBackendAuth] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const navigate = useNavigate();
  const [isCommentOpen, setIsCommentOpen] = useState(false);
  const [commentId, setCommentId] = useState(null);
  const { id } = useParams();
  const handleComments = (row) => {
    setCommentId(row?._id);
    setIsCommentOpen(true);
  };
  useEffect(() => {
    const callAuth = async() => {
      let tempAuth = await isAuthorized([],"backend");
      await setBackendAuth(tempAuth)
    }
  callAuth();
  }, [])
  

  const handleInitiatePickup = (data) => {
    updateInitiateDelivery(data._id)
      .then((response) => {
        enqueueSnackbar('Delivery Initiated!', { variant: 'success' });
      })
      .catch((error) => {
        enqueueSnackbar('Unable to Accept!', { variant: 'error' });
      });
  };

  const COLUMNS = [
    {
      id: 'index',
      label: 'SNO.',
      minWidth: 50,
      align:"center",
    },
    {
      id: 'desc',
      label: 'Description',
      minWidth: 150,
      align:"center",
      render: (val, row) => <Box sx={{width:"100%", display:'flex', justifyContent:'center'}}><Typography style={{ wordBreak: 'break-word',width: 300 , pl:3}}>{row?.line_item?.description}</Typography></Box>,
    },
    {
      id: 'pn',
      label: 'P/N',
      minWidth: 200,
      align:"center",
      render: (val, row) => row?.line_item?.PN,
    },
    {
      id: 'unitPrice',
      label: '(Client) Unit Price',
      minWidth: 120,
      align:"center",
      render: (val, row) => (
        <>
          {' '}
          {fCurrency(
            calculateClientUnitPrice(row?.line_item, projectData),
            projectData?.currency?.symbol
          )}
        </>
      ),
    },
    {
      id: 'totalPrice',
      label: '(Client) Total Price',
      minWidth: 130,
      align:"center",
      render: (value, row) => (
        <>
          {' '}
          {fCurrency(
            calculateClientUnitPrice(row?.line_item, projectData) * row?.line_item?.quantity,
            projectData?.currency?.symbol
          )}
        </>
      ),
    },
    {
      id: 'qty',
      label: 'QUANTITY',
      minWidth: 100,
      align:"center",
      render: (value, row) => row?.line_item?.quantity,
    },
    {
      id: 'unitIssue',
      label: 'Unit of Issue',
      minWidth: 120,
      align:"center",
      render: (value, row) => row?.line_item?.unit,
    },
    {
      id: 'make',
      label: 'Make',
      align:"center",
      minWidth: 200,
      render: (value, row) => (
        <Typography style={{ wordBreak: 'break-word' }}>{row?.line_item?.make}</Typography>
      ),
    },
    {
      id: 'deliveryTimeline',
      label: 'Delivery Timeline',
      minWidth: 100,
      align:"center",
      render: (value, row) =>
        `${row?.line_item?.deliveryLeadTime}  ${row?.line_item?.deliveryLeadTimeUnit}`,
    },
    {
      id: 'vendorRef',
      label: 'Vendor Type',
      minWidth: 170,
      align:"center",
      render: (val, row) => {
        return (
          <Chip
            onClick={() => {
              if (backendAuth === false){
              if (row?.type == 'vendor') {
                navigate(`/${id}/${row?.partId}/vendor-quote-orders/${row?.vendorId?._id}`);
              } else {
                navigate(`/quote/${id}/eshop/eshop-orders-delivery/${row?.partId}`);
              }}
            }}
            style={{ textTransform: 'uppercase' }}
            label={row?.type}
            color={row?.type == 'vendor' ? 'primary' : 'secondary'}
          ></Chip>
        );
      },
    },
    {
      id: 'vendorQty',
      label: 'Vendor Qty',
      minWidth: 130,
      align:"center",
      render: (value, row) => row?.quantityApproved,
    },
    {
      id: 'vendorName',
      label: 'Vendor Name',
      minWidth: 150,
      align:"center",
      render: (val, row) => (
        <Box display="flex" flexDirection="column">
          <Typography
            textTransform="uppercase"
            variant="body2"
            style={{ textDecoration: 'underline', marginBottom: 8 }}
          >
            {row?.vendorId?.name || row?.eshopName}
          </Typography>
        </Box>
      ),
    },
    {
      id: 'purchaseUnitPrice',
      label: 'Actual Purchase Unit Price',
      minWidth: 170,
      align:"center",
      render: (val, row) => <Box>{fCurrency(row?.quote?.price, row?.quote?.currency?.symbol)}</Box>,
    },
    {
      id: 'purchaseTotalPrice',
      label: 'Actual Purchase Total Price',
      minWidth: 170,
      align:"center",
      render: (val, row) => (
        <Box>
          {fCurrency(row?.quote?.price * row?.quote?.quantity, row?.quote?.currency?.symbol)}
        </Box>
      ),
    },
    {
      id: 'actualShippingCost',
      label: 'Actual Shipping Cost',
      minWidth: 170,
      align:"center",
      render: (val, row) => (
        <Box>{fCurrency(row?.actualShipping, row?.quote?.currency?.symbol)}</Box>
      ),
    },
    {
      id: 'miscAmount',
      label: 'Misc. Amount',
      minWidth: 170,
      align:"center",
      render: (val, row) => <Box>{fCurrency(row?.misc, row?.quote?.currency?.symbol)}</Box>,
    },
    {
      id: 'targetUnitPrice',
      label: 'Target Unit Price',
      minWidth: 120,
      align:"center",
      render: (val, row) => <Box>{fCurrency(row?.targetPrice, row?.quote?.currency?.symbol)}</Box>,
    },
    {
      id: 'targetTotalPrice',
      label: 'Target Total Purchase Price',
      minWidth: 170,
      align:"center",
      render: (val, row) => (
        <Box>{fCurrency(row?.targetPrice * row?.quote.quantity, row?.quote?.currency?.symbol)}</Box>
      ),
    },
    {
      id: 'vendorPoFile',
      label: 'Vendor PO File',
      minWidth: 120,
      align:"center",
      render: (val, row) =>
        backendAuth === false ?
          <>{
        row.isPOreceived ? (
          <VIewFile label="PO" sx={{ maxWidth: 200 }} value={row.poFile} mt={2} />
        ) : (
          'N/A'
        )}</>:"---",
    },
    {
      id: 'vmanualpo',
      label: 'Manual Vendor PO File',
      minWidth: 120,
      align: 'center',
      render: (val, row) =>
      backendAuth === false ?
        <>{
      row.vmanualpo ? (
        <VIewFile label="Manual Vendor PO" sx={{ maxWidth: 200 }} value={row.vmanualpo} mt={2} />
      ) : (
        'N/A'
      )}</>:"---",
    },
    {
      id: 'deliveryLeadTime',
      label: 'Vendor Delivery Lead Time',
      minWidth: 170,
      align:"center",
      render: (val, row) =>
        `${row?.quote?.deliveryTimeline} ${row?.quote?.deliveryLeadTimeUnit}`,
    },
    {
      id: 'approvalAdmin',
      label: 'Approval of Admin (Before Payment)',
      minWidth: 180,
      align:"center",
      render: (val, row) => (
        <Chip
          style={{ textTransform: 'uppercase' }}
          color={row?.adminApproval == 'Approved' ? 'success' : row?.adminApproval == 'Rejected' ? 'error' : 'warning' }
          label={row?.adminApproval ? 'Approved' : 'Pending'}
          variant="contained"
          sx={{ bgcolor: primaryPresets[6].main, color: 'black' }}
        />
      ),
    },
    {
      id: 'paymentAmtVendor',
      label: 'Advance Payment Amt. for Vendor',
      minWidth: 210,
      align:"center",
      render: (val, row) => <Box>{fCurrency(row?.amountPayed, row?.quote?.currency?.symbol)}</Box>,
    },
    {
      id: 'balanceAmtVendor',
      label: 'Balance Payment Amt. for Vendor',
      minWidth: 210,
      align:"center",
      render: (val, row) => (
        <Box>
          {fCurrency(
            row?.targetPrice * row?.quote.quantity - row?.amountPayed,
            row?.quote?.currency?.symbol
          )}
        </Box>
      ),
    },

    {
      id: 'comment',
      label: 'Comments',
      minWidth: 170,
      align:"center",
      render: (value, row) => (
        <Box
          onClick={() => {
            setShowDialog(true);
            handleComments(row);
          }}
          display="flex"
          flexDirection="column"
        >
          <Box sx={{ ml: 2.5 }}>
            {/* <Iconify width={20} icon="ic:outline-chat" /> */}
            <CountComment projectId={row?._id} isCommentOpen={isCommentOpen} />
          </Box>
        </Box>
      ),
    },
    {
      id: 'deliveryStatusShipping',
      label: 'Delivery Status from Vendor for Shipping',
      minWidth: 210,
      align:"center",
      render: (value, row) => (
        <Chip
          style={{ textTransform: 'uppercase' }}
          label="Pickup for Delivery"
          variant="contained"
          sx={{ bgcolor: primaryPresets[8].light, color: 'black' }}
          onClick={() => handleInitiatePickup(row)}
        />
      ),
    },
    {
      id: 'taxInvoiceVendor',
      label: 'Tax Invoice of Vendor',
      minWidth: 110,
      align:"center",
      render: () => (
        <Box>
          <Button
            startIcon={<Iconify width={20} icon="carbon:download" />}
            component="label"
            variant="contained"
          >
            <Typography textTransform="uppercase" variant="button">
              Download
            </Typography>
          </Button>
        </Box>
      ),
    },
  ];

  return (
    <Box>
      <CommentsModal
        isCommentOpen={isCommentOpen}
        setIsCommentOpen={setIsCommentOpen}
        id={commentId}
      />
      <Paper elevation={1} sx={{p:2, mb:1}}>
        <TableContainer sx={{ overflow: 'unset' }}>
          <Scrollbar sx={{ maxHeight: '74vh' }}>
            <Table stickyHeader sx={{ minWidth: 800 }}>
              <TableHead>
                <TableRow>
                  {COLUMNS.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, textTransform: 'uppercase', border: 1 ,background: "#D9D9D9",color: primaryPresets[11].main}}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {data.map((row) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {COLUMNS.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          style={{ verticalAlign: 'top' }}
                          key={column.id}
                          align={column.align}
                        >
                          <Typography textTransform="uppercase">
                            {' '}
                            {column.render ? column.render(value, row) : value}
                          </Typography>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>
      </Paper>
      <CommentDialog open={showDialog} handleClose={setShowDialog} />
    </Box>
  );
}
