import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import React, { useState } from 'react';

export default function AddMoreDialog(props) {
  const { open, handleClose, onSubmit } = props || {};
  const [text, setText] = useState('');

  const handleText = (e) => {
    setText(e.target.value);
  };

  const handleSubmit = () => {
    handleClose(false);
    onSubmit(text);
  };

  return (
    <Dialog open={open} onClose={() => handleClose(false)}>
      <DialogTitle>Set Title</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Comment"
          sx={{ width: 300 }}
          variant="standard"
          onChange={handleText}
        />
      </DialogContent>
      <DialogActions>
        <Button style={{ textTransform: 'uppercase' }} onClick={() => handleClose(false)}>Cancel</Button>
        <Button style={{ textTransform: 'uppercase' }} onClick={handleSubmit}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
}
