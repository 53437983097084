import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import EshopPurchasePlan from 'src/sections/eshop/view/eshopPurchasePlan';
import { HasAccess, usePermify } from '@permify/react-role';
import { useParams, useRouter } from 'src/routes/hooks';

// sections

// ----------------------------------------------------------------------

export default function EshopPurchasePlanning() {
 const { isAuthorized, isLoading } = usePermify();
 const router = useRouter();
  const [backendAuth, setBackendAuth] = useState(false);
  useEffect(() => {
    const callAuth = async() => {
      let tempAuth = await isAuthorized([],"backend");
      await setBackendAuth(tempAuth)
      if(tempAuth) {
        router.push('404');
      }
    }
  callAuth();
  }, [])
  return (
    <>
      <Helmet>
        <title> Dashboard: ESHOP Purchase Plan</title>
      </Helmet>

      <EshopPurchasePlan />
    </>
  );
}
