import PropTypes from 'prop-types';
import { useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
import axios, { endpoints } from 'src/utils/axios';
import { login as Login } from 'src/services/user';
//
import { AuthContext } from './auth-context';
import { isValidToken, setSession, jwtDecode } from './utils';
import { usePermify } from '@permify/react-role';
import { getAllCurrency } from 'src/services/common';
// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
  currency: null,
  client_details:null,
  isClientLogin:false
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
      client_details: action.payload.client_details,
      currency: action.payload.currency,
      isClientLogin:action.payload.isClientLogin
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
      client_details: action.payload.client_details,
      currency: action.payload.currency,
      isClientLogin:action.payload.isClientLogin
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      user: action.payload.user,
      client_details: action.payload.client_details,
      currency: action.payload.currency,
      isClientLogin:action.payload.isClientLogin
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
      currency: null,
      client_details:null,
      isClientLogin:false
    };
  }

  // Vendor
  if (action.type === 'VENDOR_REGISTER') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'VENDOR_LOGIN') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { setUser } = usePermify();
  // useEffect(() => {
  //   setUser({
  //     id: '2',
  //     // roles: ['manager'],
  //     // roles: ['admin'],
  //     permissions: [
  //       'quote_frontend_view',
  //       'po_frontend_view',
  //       'accounting_frontend_view',
  //       'delivery_frontend_view',
  //       '',
  //     ],
  //   });
  // }, []);
  const initialize = useCallback(async () => {
    try {
      const accessToken = sessionStorage.getItem(STORAGE_KEY);

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const response = await axios.get(endpoints.auth.me);
        const currencyData = await getAllCurrency()

        const { user, permissions, client_details } = response.data;
        console.log("response.data", response.data)
        setUser({
          id: user._id,
          // roles: ['manager'],
          // roles: ['admin'],
          permissions: permissions,
        });
        dispatch({
          type: 'INITIAL',
          payload: {
            user: {
              ...user,
              ...jwtDecode(accessToken),
              accessToken,
              permissions,
            },
            currency: currencyData,
            client_details:client_details
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
            currency: null,
            client_details:null
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
          currency: null,
          client_details:null
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email, password) => {
    const data = {
      email,
      password,
    };

    const response = await Login(email, password);

    const { token: accessToken, user, permissions, client_details, clientLogin } = response;
    console.log("responseresponse", response)

    setSession(accessToken);
    setUser({
      id: user._id,
      // roles: ['manager'],
      // roles: ['admin'],
      permissions: permissions,
    });
    const currencyData = await getAllCurrency()
    dispatch({
      type: 'LOGIN',
      payload: {
        user: {
          ...user,
          accessToken,
          permissions
        },
        currency: currencyData,
        client_details:client_details,
        isClientLogin : clientLogin
      },
    });
  }, []);

  // REGISTER
  const register = useCallback(async (email, password, firstName, lastName) => {
    const data = {
      email,
      password,
      firstName,
      lastName,
    };

    const response = await axios.post(endpoints.auth.register, data);

    const { accessToken, user, permissions } = response.data;
    setUser({
      id: user._id,
      // roles: ['manager'],
      // roles: ['admin'],
      permissions: permissions,
    });
    sessionStorage.setItem(STORAGE_KEY, accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user: {
          ...user,
          accessToken,
        },
      },
    });
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    window.location.reload();
    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  // VENDOR REGISTER
  const vendorRegister = useCallback(
    async (email, password, name, phoneNumber, address, country, region, specialization) => {
      const data = {
        email,
        password,
        name,
        phoneNumber,
        addresss: address,
        country,
        region,
        specialization,
      };

      const response = await axios.post(endpoints.auth.vendorRegister, data);

      const { data: user } = response.data;

      dispatch({
        type: 'VENDOR_REGISTER',
        payload: {
          user: {
            ...user,
          },
        },
      });
    },
    []
  );

  // VENDOR LOGIN
  const vendorLogin = useCallback(async (email, password) => {
    const data = { email, password };
    const response = await axios.post(endpoints.auth.vendorLogin, data);
    const { token: accessToken, data: user } = response.data || {};

    setSession(accessToken);

    dispatch({
      type: 'VENDOR_LOGIN',
      payload: {
        user: {
          ...user,
          accessToken,
          ...jwtDecode(accessToken),
        },
      },
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  // console.log(state);
  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      currency: state.currency,
      client_details: state.client_details,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      register,
      logout,
      vendorRegister,
      vendorLogin,
    }),
    [login, logout, register, vendorRegister, vendorLogin, state.user, state.currency, status, state.client_details]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
