import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Paper,
  Typography,
  alpha,
  TextField,
  Chip,
} from '@mui/material';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import { primaryPresets } from 'src/theme/options/presets';
import FrontendAccClientTable from '../frontendAccClientTable';
import { getAccountingDetails } from 'src/services/accounting';
import { useParams, useRouter } from 'src/routes/hooks';
import { calculateContractAmount, calculateDiscountAmount, calculateFinalAmount } from 'src/utils/quoteHelperFunc';
import { fCurrency } from 'src/utils/format-number';
import { Stack } from '@mui/system';
import _ from 'lodash';
import { LoadingButton } from '@mui/lab';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import HistoryIcon from '@mui/icons-material/History';
import { useAuthContext } from 'src/auth/hooks';
import Divider from '@mui/material/Divider';
import ContractAmountDisplay from 'src/components/common/ContractAmountDisplay';
import CommonNavigation from 'src/components/custom/CommonNavigation';
import { HasAccess, usePermify } from '@permify/react-role';
import { Check } from '@mui/icons-material';
import moment from 'moment';
import { useSnackbar } from 'src/components/snackbar';
import { generateLedgerPDF } from 'src/services/vendorAcc';
import UploadManualFiles from 'src/components/upload/UploadManualFiles';

export default function FrontendAccClientView() {
  const { id } = useParams();
  // const { user } = useAuthContext();
  const settings = useSettingsContext();
  const { isAuthorized, isLoading } = usePermify();
  const [backendAuth, setBackendAuth] = useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [response, setResponse] = useState();
  const [refresh, setRefresh] = useState(0);
  const router = useRouter();

  useEffect(() => {
    if (id) {
      getAccountingDetails(id)
        .then((response) => {
          setResponse(response);
        })
        .catch((error) => {});
    }

    const callAuth = async() => {
      let tempAuth = await isAuthorized([],"backend");
      await setBackendAuth(tempAuth)
    }
  callAuth()
  }, [id, refresh]);

  const [showChip, setShowChip] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 350) {
        setShowChip(true);
      } else {
        setShowChip(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  async function downloadLedgerFile(data) {
    setLoading(true)

   await  generateLedgerPDF(data._id).then((resp)=>{
    const pdfData = resp;
    const url = URL.createObjectURL(new Blob([pdfData], { type: 'application/pdf' }));
    const a = document.createElement('a');
    a.href = url;
    a.download = `${data.projectId}-LEDGER.pdf`;
    a.click();
    setLoading(false)
    enqueueSnackbar("LEDGER PDF GENERATED");

   }).catch((err)=>{
    enqueueSnackbar("Failed to generate PDF", { variant: 'error' });
    setLoading(false)
    // console.log("ERR", err)
   })
    
  }

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <Box display="flex">
        <Box sx={{ width: '70%' }}>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Box
              sx={{ width: '40%', borderRight: '1px solid white', pr: 3, mb: 5 }}
              display={'flex'}
              justifyContent={'space-between'}
            >
              <Box>
                <CustomBreadcrumbs
                  heading="ACCOUNTING CLIENT"
                  links={[
                    { name: 'Home', href: '/home' },
                    { name: 'ACCOUNTING LIST', href: '/home/accounting' },
                    { name: 'ACCOUNTING CLIENT', href: '/accounting' },
                  ]}
                  sx={{ mb: 2, color: 'white' }}
                />
                <Stack>
                  <Stack sx={{ mb: 2 }} direction={'row'} justifyContent={'space-between'}>
                    <Chip
                      textTransform="uppercase"
                      label={`PROJECT ID : ${response?.quote?.projectId}`}
                      style={{ 
                        background: 'gray',
                        color:'black',
                        fontWeight:'bold' ,
                        width: '100%' 
                      }}
                    />
                  </Stack>
                  <Stack direction={'row'} justifyContent={'space-between'}>
                    <Typography
                      sx={{ color: 'white' }}
                      variant="span"
                      gutterBottom
                      textTransform="uppercase"
                    >
                      CURRENCY : {response?.quote?.currency?.symbol}  {response?.quote?.currency?.abbreviation}
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
              <Typography
                sx={{ color: 'white' }}
                variant="h4"
                gutterBottom
                textTransform="uppercase"
              >
                Home
              </Typography>
            </Box>
            <Box
              sx={{ width: '60%', borderRight: '1px solid white', pr: 2, pl: 2, mb:4 }}
              display={'flex'}
              justifyContent={'space-between'}
            >
              <Box sx={{ borderRight: '1px solid white', pr: 3, mb: 9, mt: 4, width: '50%' }}>
                <ContractAmountDisplay data={response?.quote} />
                <Stack spacing={1}>
                  <Box display={'flex'} sx={{ color: 'white' }} justifyContent={'space-between'}>
                    <Typography textTransform="uppercase">Amount Received</Typography>
                    <Typography textTransform="uppercase" variant="subtitle1" sx={{ ml: 3 }}>
                      {fCurrency(
                        _.sumBy(response?.quote?.proformaInvoice, (i) => i.amount),
                        response?.quote?.currency?.symbol
                      )}
                    </Typography>
                  </Box>
                  <Divider />
                  <Box display={'flex'} sx={{ color: 'white' }} justifyContent={'space-between'}>
                    <Typography textTransform="uppercase">Balance Amount</Typography>
                    <Typography
                      textTransform="uppercase"
                      variant="subtitle1"
                      sx={{
                        color: 'white',
                        textDecoration: 'underline',
                        textDecorationColor: 'red',
                        ml: 3,
                      }}
                    >
                      {fCurrency((calculateFinalAmount(response?.quote)+(response?.quote?.cgst/100)*(calculateContractAmount(response?.quote)-calculateDiscountAmount(response?.quote))+(response?.quote?.sgst/100)*(calculateContractAmount(response?.quote)-calculateDiscountAmount(response?.quote))+(response?.quote?.igst/100)*(calculateContractAmount(response?.quote)-calculateDiscountAmount(response?.quote))+(response?.quote?.vat/100)*(calculateContractAmount(response?.quote)-calculateDiscountAmount(response?.quote))+(response?.quote?.airFreight))-
                        _.sumBy(response?.quote?.proformaInvoice, (i) => i.amount),
                        response?.quote?.currency?.symbol
                      )}
                    </Typography>
                  </Box>
                </Stack>
              </Box>
              <Box sx={{ pr: 3, pl: 3, mt: 4, pt: 4, color: 'white', width: '50%' }}>
                <Box display="flex" sx={{ width: '100%', mb: 1 }}>
                  <Box sx={{ width: '60%' }}>PROJECT ID</Box>:
                  <Box sx={{ width: '40%', pl: 3 }}>{response?.quote?.projectId}</Box>
                </Box>
                <Box display="flex" sx={{ width: '100%', mb: 1 }}>
                  <Box sx={{ width: '60%' }}>RFQ NO.</Box>:
                  <Box sx={{ width: '40%', pl: 3, wordWrap: 'break-word' }}>
                    {response?.quote?.RFQ}
                  </Box>
                 
                </Box>
                <Box display="flex" sx={{ width: '100%', mb: 1 }}>
                  <Box sx={{ width: '60%' }}>RFQ NAME</Box>:
                  <Box sx={{ width: '40%', pl: 3, wordWrap: 'break-word' }}>
                    {response?.quote?.RFQName}
                  </Box>
                </Box>
                <Box display="flex" sx={{ width: '100%', mb: 1 }}>
                  <Box sx={{ width: '60%' }}>PO NUMBER</Box>:
                  <Box sx={{ width: '40%', pl: 3 }}>{response?.quote?.clientPO}</Box>
                </Box>
                <Box display="flex" sx={{ width: '100%', mb: 1 }}>
                  <Box sx={{ width: '60%' }}>ACCEPTANCE DATE</Box>:
                  <Box sx={{ width: '40%', pl: 3 }}>
                    {moment(response?.quote?.AcceptedPODate).format('DD-MM-YYYY')}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          {showChip && (
            <Box
              sx={{
                marginTop: '10px',
                marginBottom: '10px',
              }}
            >
              <Chip
                textTransform="uppercase"
                label={`PROJECT ID : ${response?.quote?.projectId}`}
                style={{
                  width: '23.2%',
                  marginLeft: 13,
                  background: 'gray',
                  color:'black',
                  fontWeight:'bold'
                }}
              />
            </Box>
          )}
       
        </Box>
        <Box
          sx={{
            color: 'white',
            pr: 2,
            pl: 2,
            mb: 5,
            width: '30%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography>Quick Actions</Typography>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            <Button
              variant="contained"
              sx={{
                color: 'black',
                backgroundColor: '#D9D9D9',
                px: 5,
                borderRadius: 5,
                width: '40%',
                //  boxShadow: '0px 8px 10px #327B96',
                mt: 2,
              }}
            >
              Accounting
            </Button>

            <Grid item sx={{ width: '40%', mr: 2 }}>
            {backendAuth === false ? 
              <LoadingButton 
                  loading={loading}
                  sx={{
                    backgroundColor: '#D9D9D9',
                    px: 5,
                    color: 'black',
                    borderRadius: 5,
                    width: '100%',
                    mr: 2,
                    mt: 2,
                  }}
                  style={{ textTransform: 'uppercase' }}
                  variant="contained"
                  onClick={() => downloadLedgerFile(response?.quote)}
                  disabled={response?.quote?.proformaInvoice && response?.quote?.proformaInvoice.length == 0}
                >
                 DOWNLOAD LEDGER
                </LoadingButton>:
                 <Button
                 variant="contained"
                 sx={{
                   color: 'black',
                   backgroundColor: '#D9D9D9',
                   px: 5,
                   borderRadius: 5,
                   mt: 2,
                 }}
               >
                 DOWNLOAD LEDGER
               </Button>}


              </Grid>
              {/* {backendAuth === true ? 
              <Box display="flex" sx={{ width: '40%', mb: 1 }}>
                <UploadManualFiles
                    label="INVOICE - EXT"
                    path="manual/invoice"
                    disabled={true}
                    value={response?.quote?.CommercialManualInvoice ? response?.quote?.CommercialManualInvoice : []}
                    sx={{
                    //   backgroundColor: '#D9D9D9',
                    //   // p: 2,
                    //   color: 'black',
                    //   borderRadius: 5,
                    //   mt: 5,
                      width: '100%',
                    }}
                    // setValue={(val) => updateManualFiles('invoice', val)}
                    deleteSelectedFile={null}
                  />
              </Box>:null} */}
          </Box>
          
          {/*
          <Button
            variant="contained"
            sx={{ backgroundColor: '#D9D9D9', color: 'black', px: 5, borderRadius: 5, mt: 5 }}
          >
            Accounting
          </Button> */}
        </Box>
      </Box>
      <Box display={'flex'}>
            <HasAccess
              roles={['admin']}
              permissions={['accounting_frontend_view']}
              // renderAuthFailed={<p>You are not authorized to access!</p>}
            >
              <Button
                href={`/frontend-acc-client/${id}`}
                variant="contained"
                sx={{
                  backgroundColor: 'gray',
                  borderRadius: '0px',
                  marginRight: '10px',
                  ml:2,
                  color:'black',
                  borderTop: '2px solid red'
                }}
              >
                Accounting
              </Button>
            </HasAccess>
            <HasAccess
              roles={['admin']}
              permissions={['accounting_client_backend_level1_view']}
              // renderAuthFailed={<p>You are not authorized to access!</p>}
            >
              <Button
                href={`/accounting-client-level1/${id}`}
                variant="contained"
                sx={{
                  backgroundColor: 'gray',
                  borderRadius: '0px',
                  marginRight: '10px',
                  color:'black',
                  borderTop: 'none'
                }}
              >
                Accounting CLIENT L1
              </Button>
            </HasAccess>
            <HasAccess
              roles={['admin']}
              permissions={['accounting_client_backend_level2_view']}
              // renderAuthFailed={<p>You are not authorized to access!</p>}
            >
              <Button
                href={`/accounting-client-level2/${id}`}
                variant="contained"
                sx={{
                  backgroundColor: 'gray',
                  borderRadius: '0px',
                  marginRight: '10px',
                  color:'black',
                  borderTop: 'none'
                }}
              >
                Accounting CLIENT L2
              </Button>
            </HasAccess>
            {backendAuth === false ? 
            <>
            <HasAccess
              roles={['admin']}
              permissions={['accounting_vendor_backend_level1_view']}
              // renderAuthFailed={<p>You are not authorized to access!</p>}
            >
              <Button
                href={`/acc-vendor/l1/${id}`}
                variant="contained"
                sx={{
                  backgroundColor: 'gray',
                  borderRadius: '0px',
                  marginRight: '10px',
                  color:'black',
                  borderTop: 'none'
                }}
              >
                Accounting Vendor L1
              </Button>
            </HasAccess>
            <HasAccess
              roles={['admin']}
              permissions={['accounting_vendor_backend_level2_view']}
              // renderAuthFailed={<p>You are not authorized to access!</p>}
            >
              <Button
                href={`/acc-vendor/l2/${id}`}
                variant="contained"
                sx={{
                  backgroundColor: 'gray',
                  borderRadius: '0px',
                  marginRight: '10px',
                  color:'black',
                  borderTop: 'none'
                }}
              >
                Accounting Vendor L2
              </Button>
            </HasAccess>
            </>
            : null}
            {backendAuth === false ? 
          <HasAccess sx={{m:0, p:0}} roles={['admin']} permissions={['vendor_home_default_view']}>
            <Button
              disabled={response?.quote?.isCancelled}
              onClick={() => router.push(`/quote/${id}/vendor`)}
            variant="contained"
            sx={{
              backgroundColor: 'gray',
              borderRadius: '0px',
              marginRight: '10px',
              color: 'black'
            }}
            >
              Vendor HomePage
            </Button>
          </HasAccess>: null}
            <HasAccess sx={{m:0, p:0}} roles={['admin']} permissions={['quote_backend_page_1_default_view']}>
            <Button
              href={`/quote/l1/${id}`}
              variant="contained"
              sx={{
                backgroundColor: 'gray',
                borderRadius: '0px',
                marginRight: '10px',
                color:'black',
                borderTop: 'none'
              }}
            >
              QUOTE L1
            </Button>
          </HasAccess>
            <HasAccess sx={{m:0, p:0}} roles={['admin']} permissions={['purchase_order_backend_level1_view']}>
            <Button
              href = {`/poStage/purchaseOrderl1/${id}`}
              variant="contained"
              sx={{
                backgroundColor: 'gray',
                borderRadius: '0px',
                marginRight: '10px',
                color:'black',
                borderTop: 'none'
              }}
            >
              PO L1
            </Button>
          </HasAccess>
          {backendAuth === false ?
          <HasAccess sx={{m:0, p:0}} roles={['admin']} permissions={['delivery_l2']}>
            <Button
              href = {`/delivery/deliveryl2/${id}`}
              sx={{
                backgroundColor: 'gray',
                borderRadius: '0px',
                marginRight: '10px',
              }}
            >
              Delivery
            </Button>
          </HasAccess>
          :null}
          </Box>
      <FrontendAccClientTable
        response={response?.quote}
        id={id}
        setRefresh={setRefresh}
        refresh={refresh}
      />
    </Container>
  );
}
