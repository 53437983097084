import {
  Box,
  Button,
  Input,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  DialogActions,
  Stack,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useRouter } from 'src/routes/hooks';
import { primaryPresets } from 'src/theme/options/presets';
import { TablePaginationCustom, useTable } from 'src/components/table';
import { RouterLink } from 'src/routes/components';
import { getSignedURltoviewFile } from 'src/services/file';
import UploadFile from 'src/components/upload/UploadMultipleDocs';
import UploadImageFile from 'src/components/upload/UploadMutipleFIlesButton';
import UploadMultipleFIles from 'src/components/upload/UploadMutipleFIlesButton';
import {
  getDeliveryDetails,
  acceptDelivery,
  declineDelivery,
  updateStatus,
  updateTracking,
  getTracking,
  getTrackingByProjectId,
} from 'src/services/delivery';
import NewTrackingForm from 'src/sections/backendView/backendFieldTeam/NewTrackingForm';
import { useDebounce } from 'src/hooks/use-debounce';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import SignatureModal from 'src/components/common/Signature';
import { HasAccess } from '@permify/react-role';
function createData(
  sn,
  trackingNo,
  courierCompany,
  status,
  deliveryChallan,
  acceptDeliveryChallan
) {
  return {
    sn,
    trackingNo,
    courierCompany,
    status,
    deliveryChallan,
    acceptDeliveryChallan,
  };
}

export default function VendorTable({
  // trackings,
  rowsPerPage,
  // page,
  // setPage,
  setRowsPerPage,
  // totalDelivery,
  // loading,
  // fetchAllTrackings,
}) {
  const router = useRouter();
  const table = useTable({ defaultRowsPerPage: 10 });
  const [openModal, setOpenModal] = useState(false);
  const [trackingIds, setTrackings] = useState('');
  const [projectId, setProjectId] = useState('');
  const debouncedQuery = useDebounce(trackingIds);
  const debounceProjectId = useDebounce(projectId);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [trackings, setTrackingsold] = useState([]);
  const [totalDelivery, setTotalDelivery] = useState(0);
  const fetchAllTrackings = (search) => {
    getTracking(search)
      .then((response) => {
        setTrackingsold(response.trackingDetails);
        setTotalDelivery(response.totalDelivery);
        setLoading(false);
        setPage(0);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const fetchAllTrackingsByProject = (project) => {
    getTrackingByProjectId(project)
      .then((response) => {
        setTrackingsold(response.trackingDetails);
        setTotalDelivery(response.totalDelivery);
        setLoading(false);
        setPage(0);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const TABLE_DATA = [
    createData(true, '12345678', 'DHL', 1, 'avalible', 'accepted'),
    createData(false, '98765432', 'Delhivery', 2, 'avalible', 'pending'),
    createData(false, '09876543', 'Blue Dart', 3, 'avalible', 'pending'),
  ];

  useEffect(() => {
    fetchAllTrackings(debouncedQuery);
  }, [debouncedQuery]);

  useEffect(() => {
    fetchAllTrackingsByProject(debounceProjectId);
  }, [debounceProjectId]);

  const updateDeliveryStatus = async (id, status) => {
    const res = await updateStatus(id, status);
    fetchAllTrackings(debouncedQuery);
  };

  const updateDelivery = async (id, data) => {
    const res = await updateTracking(id, data);
    fetchAllTrackings(debouncedQuery);
  };

  const COLUMNS = [
    {
      id: 'id',
      label: 'Project ID',
      minWidth: 100,
      render: (value, row) => <Typography textTransform="uppercase">{row?.quoteDetails?.projectId}</Typography>,
    },
    {
      id: 'trackingNumber',
      label: 'Tracking No.',
      minWidth: 100,
      render: (value) => <Typography textTransform="uppercase">{value}</Typography>,
    },
    {
      id: 'qualityPics',
      label: 'Quality Pictures',
      minWidth: 300,
      render: (values, error, setValue) => (
        <UploadMultipleFIles
          name="qualityPics"
          label="Quality Pictures"
          disabled
          path="deliveryPictured"
          value={values}
        />
      ),
    },
    {
      id: 'courier',
      label: 'Courier Company',
      minWidth: 100,
      render: (value, row) => (
        <Stack spacing={2}>
          <Typography textTransform="uppercase">{value}</Typography>
          <UploadFile
            path={`delivery/${row._id}/customDuty`}
            label={'custom documents'}
            setValue={(val) => updateDelivery(row._id, { customDocument: val })}
            value={row.customDocument}
          />
          <Typography textTransform="uppercase">Custom Duty Link</Typography>
          <TextField
            size={'small'}
            disabled
            value={row.customDutyLink}
            onChange={(e) => updateDelivery(row._id, { customDutyLink: e.target.value })}
            InputProps={{
              endAdornment: (
                <Button
                  disabled={!row.customDutyLink}
                  size="small "
                  onClick={() => window.open(row.customDutyLink)}
                >
                  View
                </Button>
              ),
            }}
          />
          {row.isPaymentCompleted ? (
            <Chip
              style={{ textTransform: 'uppercase' }}
              label={'Payment Completed'}
              color="primary"
            />
          ) : (
            <Chip
              style={{ textTransform: 'uppercase' }}
              label={'Payment Not Completed'}
              color="secondary"
            />
          )}
        </Stack>
      ),
    },
    {
      id: 'status',
      label: 'Status',
      minWidth: 100,
      render: (value, row) => (
        <Stack spacing={2}>
          <Chip style={{ textTransform: 'uppercase' }} label={'Pickup'} color="primary" />
          <Chip style={{ textTransform: 'uppercase' }} label={'In Transit'} color="primary" />
          <Chip
            style={{ textTransform: 'uppercase' }}
            label={'Custom Clearance Event'}
            color="primary"
          />
          <Chip
            style={{ textTransform: 'uppercase' }}
            label={'Delivered at SkyBiz Hub'}
            color={
              row.statusUpdates.find((i) => i.status == 'Shipment reached at SkyBiz Hub')
                ? 'primary'
                : 'secondary'
            }
            onClick={() =>
              updateDeliveryStatus(row._id, {
                status: 'Shipment reached at SkyBiz Hub',
              })
            }
          />
          <Chip
            style={{ textTransform: 'uppercase' }}
            label={'Out for Delivery'}
            color={
              row.statusUpdates.find((i) => i.status == 'Shipment is out for Delivery.')
                ? 'primary'
                : 'secondary'
            }
            onClick={() =>
              updateDeliveryStatus(row._id, {
                status: 'Shipment is out for Delivery.',
              })
            }
          />
        </Stack>
      ),
    },
    {
      id: 'deliveryChallan',
      label: 'Delivery Challan',
      minWidth: 100,
      align: 'center',
      render: (value, row) => (
        value ? 
        <Button
          style={{ textTransform: 'uppercase' }}
          // href=""
          onClick={async () => {
            const link = await getSignedURltoviewFile(value);

            window.open(link);
          }}
          variant="outlined"
          // startIcon={<Iconify icon="mingcute:add-line" />}
        >
          View
        </Button>
        :<Typography textTransform="uppercase">Not Generated</Typography>

      ),
    },
    {
      id: 'isDeliveryChallanAccepted',
      label: 'Accept Delivery Challan',
      minWidth: 100,
      align: 'center',
      render: (value, row) => (
        <Box flexDirection="column" display="flex" justifyContent="flex-end">
          <Box flexDirection="row" display="flex" justifyContent="flex-end">
            <Button
              style={{ textTransform: 'uppercase' }}
              disabled={row.isDeliveryChallanAccepted}
              onClick={() => {
                updateDelivery(row._id, { isDeliveryChallanAccepted: true });
              }}
              sx={{ mr: 3, marginBottom: '1rem', backgroundColor: primaryPresets[6].dark }}
              variant="contained"
            >
              {row.isDeliveryChallanAccepted ? 'Challan Accepted' : 'Accept Challan'}
            </Button>
          </Box>
          <Button
            disabled={row.isDelivered}
            onClick={() => {
              updateDelivery(row._id, { isCompleted: true, isDelivered: true });
            }}
            style={{ textTransform: 'uppercase' }}
            sx={{ my: 1 }}
            variant="outlined"
          >
            Delivery Completed
          </Button>
          {row.isDelivered && (
            <Box sx={{ mt: 3 }} flexDirection="row" display="flex" justifyContent="space-between">
              <UploadImageFile
                path={`delivery/${row._id}/proffOfDelivery`}
                label={'Upload Proof of Delivery'}
                setValue={(val) => updateDelivery(row._id, { proffOfDelivery: val })}
                value={row.proffOfDelivery}
              />
            </Box>
          )}

          <SignatureModal value={row?.signature ?  [row.signature] : []} setValue={(val) => updateDelivery(row._id, val)} />
        </Box>
      ),
    },
  ];

  return (
         <HasAccess roles={['admin']} permissions={['field_team_level4_view']}
    renderAuthFailed={
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="50vh"
        color="red"
        fontWeight="700"
        fontSize={20}
      >
       <Typography sx={{color:'red', fontSize:24}}>You Do Not Have Access</Typography>
      </Box>
    }>
    <Box>
      {/* <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogTitle>Create new Tracking</DialogTitle>
        <DialogContent>
          <NewTrackingForm
            onClose={() => setOpenModal(false)}
            fetchAllTrackings={fetchAllTrackings}
          />
        </DialogContent>
  
      </Dialog> */}
      <Box display="flex" width="100%" justifyContent="flex-end" alignItems='center'>
      <Typography textTransform="uppercase" variant='h4' sx={{color:'white', mr:3}}>Project ID:</Typography>
        <TextField
          size="medium"
          value={projectId}
          onChange={(e) => setProjectId(e.target.value)}
          sx={{ mr: 2 }}
          placeholder="Search for Project Id"
        />
      </Box>
      <Box display="flex" width="100%" justifyContent="flex-end" alignItems='center' py={2}>
      <Typography textTransform="uppercase" variant='h4' sx={{color:'white', mr:3}}>Tracking ID:</Typography>
        <TextField
          size="medium"
          value={trackingIds}
          onChange={(e) => setTrackings(e.target.value)}
          sx={{ mr: 2 }}
          placeholder="Search for tracking code"
        />
      </Box>
      <Box display="flex" width="100%" justifyContent="flex-end" py={2}>
      <Button
          style={{ textTransform: 'uppercase' }}
          variant="contained"
          onClick={() => setOpenModal(true)}
        >
          Scan Barcode
        </Button>
      </Box>
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <DialogTitle>Scan Barcode</DialogTitle>
        <DialogContent>
          <BarcodeScannerComponent
            width={500}
            onUpdate={(err, result) => {
              if (result) {
                setTrackings(result.text);
                setOpenModal(false);
              } else console.log('Not Found');
            }}
            stopStream={!openModal}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenModal(false);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Paper elevation={1}>
        <TableContainer sx={{ overflow: 'unset' }}>
          <Table stickyHeader sx={{ minWidth: 800 }}>
            <TableHead>
              <TableRow>
                {COLUMNS.map((column) => (
                  <TableCell
                    style={{
                      minWidth: column.minWidth,
                      textTransform: 'uppercase',
                      color: primaryPresets[11].main,
                      borderBottom: '1px solid',
                      background: 'white',
                    }}
                    key={column.id}
                    align={column.align}
                    // style={{ minWidth: column.minWidth, textTransform: 'uppercase', border: 1 }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      flexDirection="column"
                      alignItems="center"
                      width="100%"
                      height={220}
                    >
                      <CircularProgress />
                      <br />
                      <Typography textTransform="uppercase">Fetching...</Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {trackings?.map((row) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {COLUMNS?.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ textTransform: 'uppercase' }}
                        >
                          {column.render ? column.render(value, row) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </Box>
    </HasAccess>
  );
}
