import {
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  InputAdornment,
  styled,
} from '@mui/material';
import * as Yup from 'yup';
import { useState, useMemo, useEffect } from 'react';
import { useSnackbar } from 'src/components/snackbar';
import CommentDialog from 'src/components/comment-dialog';
import CommentsModal from 'src/components/comments/index';
import Iconify from 'src/components/iconify/iconify';
import { TablePaginationCustom, useTable } from 'src/components/table';
import { primaryPresets } from 'src/theme/options/presets';
import { fCurrency } from 'src/utils/format-number';
import Scrollbar from 'src/components/scrollbar';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form } from 'react-hook-form';
import UploadFile from 'src/components/upload/uploadFileButton';
import { quoteRequest, quoteRequestSave } from 'src/services/vendorDashboard';
import {
  RHFSelect,
  RHFSwitch,
  RHFTextField,
  RHFMultiSelect,
  RHFAutocomplete,
  RHFMultiCheckbox,
} from 'src/components/hook-form';
import VIewFile from 'src/components/upload/ViewFIle';
import { useParams } from 'src/routes/hooks';
import CountComment from 'src/components/comments/CountComment';
import ImageUploadButton from 'src/components/upload/UploadMutipleFIlesButton';
import { HasAccess } from '@permify/react-role';

export default function VendorQuoteSubmissionTable({ requestData, currency }) {
  const table = useTable({ defaultRowsPerPage: 10 });
  const [formData, setFormData] = useState({});
  const [showDialog, setShowDialog] = useState(false);
  const [file, setFile] = useState('');
  const { id } = useParams();
  const [isCommentOpen, setIsCommentOpen] = useState(false);
  const [commentId, setCommentId] = useState(null);


  const handleComments = (row) => {
    setCommentId(row?._id);
    setIsCommentOpen(true);
  };

  const COLUMNS = [
    { id: 'slNo', label: 'SNO.', minWidth: 50, align: 'center' },

    {
      id: 'desc',
      label: 'Description',
      minWidth: 220,
      render: (values, errors, handleUpdateValue) => (
        <Box display="flex" flexDirection="column">
          <TextField
            sx={{ textTransform: 'uppercase' }}
            disabled={values.isQuoteApproved}
            multiline
            minRows={3}
            name="description"
            label="description"
            onChange={(e)=> handleUpdateValue("description",e.target.value)}
            value={values?.description}
            error={Boolean(errors.description)}
            helperText={errors.description || ''}
          />
          <Box display="flex" flexDirection="row" alignItems="center" mt={2} width={'100%'}>
            {/* <UploadFile
              disabled={values.isQuoteApproved}
              label="image"
              path={`test/vendorrequest/${values._id}/quote/image`}
              error={errors?.image?.message}
              value={values.image}
              setValue={(val) => setValue('image', val)}
            /> */}
          </Box>
          <ImageUploadButton
            mt={2}
            label="Image"
            disabled={true}
            path={`test/vendorrequest/${values._id}/image`}
            value={values?.image}
            // setValue={(val) => handleTextFieldChange('image', val, row?._id, row?.index)}
          />
          {/* <Typography
            textTransform="uppercase"
            style={{ textDecoration: 'underline' }}
            variant="subtitle2"
            component="span"
            sx={{ mb: 0.8, mt: 0.8 }}
          >
            {formData?.descImage}
          </Typography> */}
          {/* <Box display="flex" flexDirection="row">
            {file && <img height={64} width={64} src={file} alt="file" />}
            <List sx={{ ml: 1.8 }}>
              {imageInstructions.map((value, ind) => (
                <ListItem key={value} disableGutters>
                  <ListItemText>{`${ind + 1}. ${value}`}</ListItemText>
                </ListItem>
              ))}
            </List>
          </Box> */}
        </Box>
      ),
    },
    {
      id: 'PN',
      label: 'PN',
      minWidth: 170,
    },
    {
      id: 'complianceDeclaration',
      label: 'Compliance Declaration',
      minWidth: 160,
      render: (values,errors,handleUpdateValue) => (
        <TextField
          sx={{ textTransform: 'uppercase' }}
          disabled={values.isQuoteApproved}
          multiline
          minRows={3}
          name="compliance"
          label="compliance"
          onChange={(e)=> handleUpdateValue("compliance",e.target.value)}
          value={values?.compliance}
          error={Boolean(errors.compliance)}
          helperText={errors.compliance || ''}
        />
      ),
    },
    {
      id: 'qty',
      label: 'QUANTITY',
      minWidth: 170,
      render: (values,errors,handleUpdateValue) => (
        <TextField
          sx={{ textTransform: 'uppercase' }}
          disabled={values.isQuoteApproved}
          type="number"
          name="quantity"
          label="quantity"
          onChange={(e)=> handleUpdateValue("quantity",e.target.value)}
          value={values?.quantity}
          error={Boolean(errors.quantity)}
          helperText={errors.quantity || ''}
        />
      ),
    },
    {
      id: 'unit',
      label: 'unit of issue',
      minWidth: 170,
    },
    {
      id: 'hsnCode',
      label: 'HSN Code',
      minWidth: 170,
      render: (values,errors,handleUpdateValue) => (
        <TextField
          sx={{ textTransform: 'uppercase' }}
          disabled={values.isQuoteApproved}
          name="HSN"
          label="HSN"
          onChange={(e)=> handleUpdateValue("HSN",e.target.value)}
          value={values?.HSN}
          error={Boolean(errors.HSN)}
          helperText={errors.HSN || ''}
        />
      ),
    },
    {
      id: 'make',
      label: 'Make',
      minWidth: 170,
      render: (values,errors,handleUpdateValue) => (
        <RHFTextField
          sx={{ textTransform: 'uppercase' }}
          disabled={values.isQuoteApproved}
          name="make"
          label="make"
          onChange={(e)=> handleUpdateValue("make",e.target.value)}
          value={values?.make}
          error={Boolean(errors.make)}
          helperText={errors.make || ''}
        />
      ),
    },
    {
      id: 'vendorshipping',
      label: 'DELIVERY METHOD',
      minWidth: 170,
      render: (values,errors,handleUpdateValue) => (
        <Select name="vendorshipping" label="DELIVERY METHOD"  onChange={(e)=> handleUpdateValue("vendorshipping",e.target.value)}
        value={values?.vendorshipping}
        error={Boolean(errors.vendorshipping)}
        helperText={errors.vendorshipping || ''}>
          <MenuItem key={true} value={true}>
            BY VENDOR
          </MenuItem>
          <MenuItem key={false} value={false}>
            OTHER
          </MenuItem>
        </Select>
      ),
    },
    {
      id: 'vendorshippingFee',
      label: 'EST. SHIPPING COST',
      minWidth: 170,
      render: (values,errors,handleUpdateValue) => (
        <TextField
          sx={{ textTransform: 'uppercase' }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">{currency?.symbol || '$'}</InputAdornment>
            ),
          }}
          disabled={values.isQuoteApproved}
          type="number"
          name="vendorshippingFee"
          label="vendorshippingFee"
          onChange={(e)=> handleUpdateValue("vendorshippingFee",e.target.value)}
          value={values?.vendorshippingFee}
          error={Boolean(errors.vendorshippingFee)}
          helperText={errors.vendorshippingFee || ''}
        />
      ),
    },
    {
      id: 'deliveryTimeline',
      label: 'Delivery Timeline',
      minWidth: 170,
      render: (values,errors,handleUpdateValue) => (
        <TextField
          sx={{ textTransform: 'uppercase' }}
          disabled={values.isQuoteApproved}
          type="number"
          name="deliveryTimeline"
          label="deliveryTimeline"
          onChange={(e)=> handleUpdateValue("deliveryTimeline",e.target.value)}
          value={values?.deliveryTimeline}
          error={Boolean(errors.deliveryTimeline)}
          helperText={errors.deliveryTimeline || ''}
        />
      ),
    },
    {
      id: 'deliveryLeadTimeUnit',
      label: 'Delivery Lead Time Unit',
      minWidth: 170,
      render: (values,errors,handleUpdateValue) => {
        return (
          // <RHFTextField
          //   sx={{ textTransform: 'uppercase' }}
          //   disabled={values.isQuoteApproved}
          //   // type="number"
          //   name="deliveryLeadTimeUnit"
          //   label="deliveryLeadTimeUnit"
          // />
          // <Select
          //   width="100%"
          //   label="deliveryLeadTimeUnit"
          //   name="deliveryLeadTimeUnit"
          //   value={values}
          //   disabled={values.isQuoteApproved}
          //   sx={{ width: 220 }}
          //   // onChange={(e) => handleTextFieldChange('deliveryLeadTimeUnit', e, row?._id, row?.index)}
          // >
          //   <MenuItem value="">Choose</MenuItem>
          //   <MenuItem value="Days">Days</MenuItem>
          //   <MenuItem value="Weeks">Weeks</MenuItem>
          //   <MenuItem value="Months">Months</MenuItem>
          // </Select>
          <Select
            name="deliveryLeadTimeUnit"
            label="deliveryLeadTimeUnit"
            disabled={values.isQuoteApproved}
            onChange={(e)=> handleUpdateValue("deliveryLeadTimeUnit",e.target.value)}
            value={values?.deliveryLeadTimeUnit}
            error={Boolean(errors.deliveryLeadTimeUnit)}
            helperText={errors.deliveryLeadTimeUnit || ''}
          >
            <MenuItem key={'Days'} value={'Days'}>
              Days
            </MenuItem>
            <MenuItem key={'Weeks'} value={'Weeks'}>
              Weeks
            </MenuItem>
            <MenuItem key={'Months'} value={'Months'}>
              Months
            </MenuItem>
          </Select>
        );
      },
    },
    {
      id: 'purchaseUnit',
      label: 'Vendor Unit Price',
      minWidth: 170,
      render: (values, errors,handleUpdateValue) => {
        // console.log(values);
        return (
          <TextField
            sx={{ textTransform: 'uppercase' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{currency?.symbol || '$'}</InputAdornment>
              ),
            }}
            disabled={values.isQuoteApproved}
            type="number"
            name="price"
            onChange={(e)=> handleUpdateValue("price",e.target.value)}
            value={values?.price}
            error={Boolean(errors.price)}
            helperText={errors.price || ''}
          />
        );
      },
    },
    {
      id: 'purchaseTotal',
      label: 'Vendor Total Price',
      minWidth: 170,
      render: (values) => fCurrency(values.price * values.quantity, currency?.symbol),
    },
    {
      id: 'datasheetUpload',
      label: 'Datasheet File Upload',
      minWidth: 170,
      render: (values, errors, handleUpdateValue) => (
        <UploadFile
          disabled={values.isQuoteApproved}
          label="datasheet"
          path={`test/ /${values._id}/quote/datasheet`}
          error={errors?.dataSheetFile?.message}
          value={values.dataSheetFile}
          setValue={(val) => handleUpdateValue('dataSheetFile', val)}
        />
      ),
    },
    {
      id: 'certificateConformity',
      label: 'Certificate of Conformity',
      minWidth: 170,
      render: (values, errors, handleUpdateValue) => (
        <UploadFile
          disabled={values.isQuoteApproved}
          label="COC"
          path={`test/vendorrequest/${values._id}/quote/coc`}
          error={errors?.COC?.message}
          value={values.COC}
          setValue={(val) => handleUpdateValue('COC', val)}
        />
      ),
    },
    {
      id: 'vendorQuoteFile',
      label: 'Vendor Quote File',
      minWidth: 133,
      render: (values, errors, handleUpdateValue) => (
        <UploadFile
          disabled={values.isQuoteApproved}
          label="Quote file"
          path={`test/vendorrequest/${values._id}/quote/vendorquotefile`}
          error={errors?.vendorQuoteFile?.message}
          value={values.vendorQuoteFile}
          setValue={(val) => handleUpdateValue('vendorQuoteFile', val)}
        />
      ),
    },
    {
      id: 'comment',
      label: 'Comment',
      minWidth: 170,
      render: (values, row) => (
        <Box
          onClick={() => {
            setShowDialog(true);
            handleComments(values);
          }}
          display="flex"
          flexDirection="column"
        >
          <Box sx={{ ml: 2.5 }}>
            {/* <Iconify width={20} icon="ic:outline-chat" /> */}
            <CountComment projectId={row?._id} isCommentOpen={isCommentOpen} />
          </Box>
          <Typography
            textTransform="uppercase"
            variant="caption"
            component="span"
            sx={{ mr: 0.4, width: 20 }}
          >
            View/Write Comment
          </Typography>
        </Box>
      ),
    },
    {
      id: 'vendorPoFile',
      label: 'Vendor PO File',
      minWidth: 120,
      render: (values, row) => (
        <Box display="flex" flexDirection="column">
          {values.isPOreceived ? (
            <HasAccess roles={['admin']} permissions={['vendor_view_po_file']}>
              <VIewFile label="PO" value={values.poFile} mt={2} />
            </HasAccess>
          ) : (
            //   <>
            <HasAccess roles={['admin']} permissions={['vendor_generate_po_file']}>
              <Button
                variant="contained"
                disabled={!values.isQuoteApproved}
                href={`/quote/generatePOPdf/${values?._id}/${id}`}
                startIcon={<Iconify width={20} icon="heroicons:folder-plus-20-solid" />}
              >
                <Typography textTransform="uppercase" variant="button">
                  Generate
                </Typography>
              </Button>
            </HasAccess>
          )}
        </Box>
      ),
    },
  ];

  return (
    <Box>
      <Paper elevation={1}>
        <TableContainer sx={{ overflow: 'unset' }}>
          <Scrollbar sx={{ maxHeight: 400 }}>
            <Table stickyHeader sx={{ minWidth: 800 }}>
              <TableHead>
                <TableRow>
                  {COLUMNS.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, textTransform: 'uppercase', border: 1 }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                  <TableCell
                    style={{
                      position: 'sticky',
                      right: 0,
                      //background: '#fff',
                      zIndex: 200,
                      borderLeft: '1px solid #fafafa',
                      textTransform: 'uppercase',
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {requestData.map((row, index) => (
                  <CustomTableRow
                    COLUMNS={COLUMNS}
                    row={{ ...row, slNo: index + 1 }}
                    currency={currency}
                  />
                ))}
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>
        {/*
        <TablePaginationCustom
          count={TABLE_DATA.length}
          page={table.page}
          rowsPerPage={table.rowsPerPage}
          onPageChange={table.onChangePage}
          onRowsPerPageChange={table.onChangeRowsPerPage}
        /> */}
      </Paper>
      <CommentsModal
        isCommentOpen={isCommentOpen}
        setIsCommentOpen={setIsCommentOpen}
        id={commentId}
      />
      <CommentDialog open={showDialog} handleClose={setShowDialog} />
    </Box>
  );
}

const CustomTableRow = ({ COLUMNS, row, currency }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [errors, setErrors] = useState({});
  const [reason, setReason] = useState("");
  const [approvalChanges, setApprovalChanges] = useState([]);
  const QuoteSchema = Yup.object().shape({
    compliance: Yup.string(),
    quantity: Yup.number().min(1).required('quantity is required'),
    HSN: Yup.string().required('HSN is required'),
    COC: Yup.string(),
    vendorshippingFee: Yup.number().required('Estimated Shipping Cost Is required'),
    vendorshipping: Yup.boolean().required('Delivery Method Required'),
    make: Yup.string().required('make is required'),
    deliveryTimeline: Yup.number('Enter number of days')
      .min(1)
      .required('deliveryTimeline is required'),
    price: Yup.number().min(1).required('price is required'),
    vendorQuoteFile: Yup.string(),
    dataSheetFile: Yup.string(),
    description: Yup.string().required('description is required'),

    image: Yup.array().of(Yup.string()),
  });

  const defaultValues = useMemo(
    () => ({
      compliance: row?.quote?.compliance || row.Compliance,
      quantity: row?.quote?.quantity || row.quantity,
      HSN: row?.quote?.HSN || row.HSN,
      COC: row?.quote?.COC || '',
      vendorshippingFee: row?.quote?.vendorshippingFee || '',
      vendorshipping:
        row?.quote?.vendorshipping === false
          ? row?.quote?.vendorshipping
          : row?.quote?.vendorshipping === true
          ? row?.quote?.vendorshipping
          : '',
      make: row?.quote?.make || row.make,
      deliveryTimeline: row?.quote?.deliveryTimeline || row.deliveryTimeline,
      deliveryLeadTimeUnit: row?.quote?.deliveryLeadTimeUnit || row?.quote?.deliveryLeadTimeUnit,
      price: row?.quote?.price || row.price,
      vendorQuoteFile: row?.quote?.vendorQuoteFile || '',
      dataSheetFile: row?.quote?.dataSheetFile || '',
      description: row?.quote?.description || row.description,
      image: row?.image || [],
      currency: currency || {},
      _id: row._id,
      isQuoteSubmitted: row?.isQuoteSubmitted,
      isQuoteApproved: row?.isQuoteApproved,
      isApproved: row?.isApproved,
      isPOreceived: row?.isPOreceived,
      isActive: row?.isActive,
      poFile: row?.poFile,
      unit: row?.unit,
    }),
    [row, currency]
  );


  

  const [values, setValues] = useState({
    compliance: row?.quote?.compliance || row.Compliance,
      quantity: row?.quote?.quantity || row.quantity,
      HSN: row?.quote?.HSN || row.HSN,
      COC: row?.quote?.COC || '',
      vendorshippingFee: row?.quote?.vendorshippingFee || '',
      vendorshipping:
        row?.quote?.vendorshipping === false
          ? row?.quote?.vendorshipping
          : row?.quote?.vendorshipping === true
          ? row?.quote?.vendorshipping
          : '',
      make: row?.quote?.make || row.make,
      deliveryTimeline: row?.quote?.deliveryTimeline || row.deliveryTimeline,
      deliveryLeadTimeUnit: row?.quote?.deliveryLeadTimeUnit || row?.quote?.deliveryLeadTimeUnit,
      price: row?.quote?.price || row.price,
      vendorQuoteFile: row?.quote?.vendorQuoteFile || '',
      dataSheetFile: row?.quote?.dataSheetFile || '',
      description: row?.quote?.description || row.description,
      image: row?.image || [],
      currency: currency || {},
      _id: row._id,
      isQuoteSubmitted: row?.isQuoteSubmitted,
      isQuoteApproved: row?.isQuoteApproved,
      isApproved: row?.isApproved,
      isPOreceived: row?.isPOreceived,
      isActive: row?.isActive,
      poFile: row?.poFile,
      unit: row?.unit,
    });

    useEffect(() => {
      setValues({...values,currency:currency})
     }, [currency])
  const methods = useForm({
    resolver: yupResolver(QuoteSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    setValue,
    handleSubmit,
    clearErrors,
    formState: { isSubmitting },
  } = methods;

  // const values = watch();

  useEffect(() => {
    if (!values?.isActive) {
      quoteRequestSave(values?._id, { ...values, currency: currency._id });
    }
  }, [values]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const onSubmit = async (data) => {
    try {
      QuoteSchema.validateSync(values, { abortEarly: false });
      await quoteRequest(data?._id, { ...data, isActive: true, currency: currency._id });
      enqueueSnackbar('Vendor quote submitted');
      handleUpdateValue('isQuoteSubmitted', true);
      handleUpdateValue('isActive', true);
      setErrors({})
    } catch (error) {
      setErrors(
        error.inner.reduce((errors, err) => {
          errors[err.path] = err.message;
          return errors;
        }, {})
      );
      console.log(error,"error submit");
      enqueueSnackbar( error?.data?.message ?  error.data.message : 'Error in Submitting Quote!', { variant: 'error' });
    }
  };

  const handleUpdateValue = (fieldName, targetVal) => {
    setValues({ ...values, [fieldName]: targetVal });
  };

  const UpdateHandler = async() => {
    try {
      QuoteSchema.validateSync(values, { abortEarly: false });
      var resp = await quoteRequest(values?._id, { ...values, isActive: true, currency: values?.currency?._id });
      enqueueSnackbar('Quote Updated Successfully');
      setErrors({})

    } catch (error) {
      console.log(error,"error update");
      setErrors(
        error.inner.reduce((errors, err) => {
          errors[err.path] = err.message;
          console.log(errors,"erorrs")
          return errors;
        }, {})
      );
      enqueueSnackbar( error?.data?.message ?  error.data.message : 'Error in Updating Quote!', { variant: 'error' });
    }
  };

  return (
    <FormProvider {...methods}>
      <TableRow tabIndex={-1} key={row.code}>
        {COLUMNS.map((column) => {
          const value = row[column.id];
          return (
            <TableCell key={column.id} align={column.align} style={{ textTransform: 'uppercase' }}>
              {column.render ? column.render(values, errors, handleUpdateValue) : value}
            </TableCell>
          );
        })}
        <TableCell
        style={{
          position: 'sticky',
          right: 0,
          background: '#fafafa',
          zIndex: 100,
          // border: '1px solid grey',
          align: 'center',
        }}
      >
        {!values?.isActive ? (
          <>
            <Button
              style={{ textTransform: 'uppercase', mb: 2 }}
              variant="outlined"
              onClick={() => onSubmit(values)}
            >
              Submit
            </Button>
          </>
        ) : (
          <>
          {/* // row?.isLocked ? 
          // <> */}
          {/* <Button
            style={{ textTransform: 'uppercase', mb: 2 }}
            variant="contained"
            color="warning"
            disabled={values.isApproved}
            // onClick={() => ApproveChangesCycle()}
          >
            Admin Aprrove changes
          </Button>
          <Typography sx={{mt:2 ,fontSize:12, color: '#CC0100'}}>
          Reason: <Typography sx={{mt:2 , color: 'black'}} variant='span'>{approvalChanges?.submissionReasonText}</Typography>
          </Typography>
          <TextField
          label="Approval Reason"
          name={'reason'}
          size="small"
          value={reason}
          onChange={(e)=> setReason(e.target.value)}
          sx={{ mb: 1.2,mt:2, textTransform: 'uppercase' }}
        /> </>: */}
          <Button
            style={{ textTransform: 'uppercase', mb: 2 }}
            variant="outlined"
            disabled={values?.isApproved ===true}
            onClick={() => UpdateHandler(values)}
          >
            Update
          </Button>
          {/* <TextField
          label="Reason"
          name={'reason'}
          size="small"
          value={reason}
          onChange={(e)=> setReason(e.target.value)}
          sx={{ mb: 1.2,mt:2, textTransform: 'uppercase' }}
        /> */}
          </>
        )} 
         {/* <Button
         style={{ marginTop: 5, textTransform: 'uppercase', backgroundColor: '#26275c', color:'white' }}
         variant="contained"
        //  onClick={(e) => HandleArchiveRequest(row?._id)}
       >
         Archive
       </Button> */}
      </TableCell>
      </TableRow>
    </FormProvider>
  );
};
