import { alpha } from '@mui/material/styles';
import { inputBaseClasses } from '@mui/material/InputBase';
import { inputLabelClasses } from '@mui/material/InputLabel';
import { filledInputClasses } from '@mui/material/FilledInput';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';

// ----------------------------------------------------------------------

export function textField(theme) {
  const color = {
    focused: theme.palette.text.primary,
    active: theme.palette.text.secondary,
    placeholder: theme.palette.text.disabled,
  };

  const font = {
    label: theme.typography.body1,
    value: theme.typography.body2,
  };

  return {
    MuiFormControl: {
      styleOverrides: {
        fullWidth: {
          marginTop: theme.spacing(2),
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          borderRadius: 2,
          marginTop: 12,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: color.placeholder,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          ...font.label,
          color: color.active,
          top: 12,
          '&$focused': { color: color.focused },
        },
        shrink: {
          top: 0,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            backgroundColor: '#f0f0f0',
          },
        },
      },
    },
    // HELPER
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: theme.spacing(1),
        },
      },
    },
  };
}
