import { Helmet } from 'react-helmet-async';

import ClientSearchPageSection from 'src/sections/backendView/clientSearchPageSection/index';

export default function clientSearchPage() {
  return (
    <>
      <Helmet>
        <title>Search</title>
      </Helmet>
      <ClientSearchPageSection />
    </>
  );
}
