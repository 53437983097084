import React, { useEffect, useState } from 'react';
import { Box, Button, Chip, Container, Typography, alpha } from '@mui/material';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';

import { useParams, useRouter } from 'src/routes/hooks';
import CurrencyPicker from 'src/components/custom/CurrencySelect';
import VendorQuoteSubmissionTable from '../vendorQuoteSubmissionTable';
import { useOutletContext } from 'react-router';
import { getAllRequestofacceptedProjects } from 'src/services/vendorDashboard';
import VendorQuotesNav from 'src/components/Navs/VendorQuotesNav';
export default function VendorQuoteSubmissionView() {
  const settings = useSettingsContext();
  const router = useRouter();
  const { id, partId,vid } = useParams();
  const [data] = useOutletContext();
  const [requestData, setRequestData] = useState([]);
  const [currency, setCurrency] = useState({});
  console.log(requestData,"requestData")

  useEffect(() => {
    getAllRequestofacceptedProjects(id, partId,vid).then((vdata) => {
      setRequestData(vdata.data);
      setCurrency(vdata.data[0]?.quote?.currency);
    });
  }, []);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      {vid?
      <CustomBreadcrumbs
        heading="Vendor Quote Submission"
        links={[
          { name: 'Home', href: '/' },
          {
            name: 'Vendor',
            href: `/quote/${id}/vendor`,
          },
          {
            name: 'Vendor Quote Submission',
            href: '/vendor-quote/vendor-quote-submission',
          },
        ]}
        sx={{ mb: { xs: 3, md: 5 } }}
      />:
      <CustomBreadcrumbs
      heading="Vendor Quote Submission"
      links={[
        { name: 'Home', href: '/' },
        {
          name: 'Vendor Quote Submission',
          href: '/vendor-quote/vendor-quote-submission',
        },
      ]}
      sx={{ mb: { xs: 3, md: 5 } }}
    />}
      <Box display="flex" justifyContent="space-between">
        <VendorQuotesNav id={id} url={`/${id}/${partId}`} vid={vid} currentTab={"quote"}/>
        <CurrencyPicker selectedValue={currency?._id} onChange={(e, val) => setCurrency(val)} />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          width: 1,
          paddingLeft: 4,
          paddingRight: 4,
          paddingTop: 3,
          paddingBottom: 3,
          borderRadius: 2,
          mt: 2,
          bgcolor: (theme) => alpha(theme.palette.grey[500], 0.04),
          border: (theme) => `solid 1px ${theme.palette.divider}`,
        }}
      >
        <Box>
          <Chip
            style={{ borderRadius: 1, marginRight: 10, textTransform: 'uppercase', color: 'white' }}
            label={`Project ID: ${data.projectId}`}
            variant="outlined"
          />
          <Chip
            style={{ borderRadius: 1, textTransform: 'uppercase', color:'white' }}
            label={`RFQ: ${data.RFQ}`}
            variant="outlined"
          />
          <Chip
            style={{ borderRadius: 1, textTransform: 'uppercase', color:'white' }}
            label={`RFQ NAME: ${data.RFQName}`}
            variant="outlined"
          />
        </Box>
        {/* <Box display="flex" flexDirection="column">
          <Typography variant="caption">{'Recently edited by - {User Name}'}</Typography>
          <Box sx={{ mt: 1.7 }} display="flex" justifyContent="space-between" alignItems="center">
            <Button style={{ borderRadius: 1 }} variant="outlined">
              <Typography textTransform="uppercase" variant="button">
                EDIT
              </Typography>
            </Button>
            <Button style={{ borderRadius: 1, marginLeft: 32 }} variant="outlined">
              <Typography textTransform="uppercase" variant="button">
                SUBMIT
              </Typography>
            </Button>
          </Box>
        </Box> */}
      </Box>

      <VendorQuoteSubmissionTable requestData={requestData} currency={currency} />
    </Container>
  );
}
