import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { CancelItem } from '../../services/common';
import { useSnackbar } from 'src/components/snackbar';

import { rollbackQuoteService, rejectQuoteService, enquireRejectQuoteService } from 'src/services/quotes';
import { ConfirmBox } from '../custom-confirm-box';
const CancelButton = ({ id, type, disabled }) => {
  const [isLoading, setLoading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [confirmBoxOpen, setConfirmBoxOpen] = React.useState(false);
  const handleConfirm = () => {
    setConfirmBoxOpen(false);
    onCancel(true)
  }

  const onCancel = async (forceCancel) => {
    setLoading(true);
    if (forceCancel) {
      try {
        const res = await rejectQuoteService(id);
        enqueueSnackbar(res.message, { variant: 'success' });
        window.location.reload();
      } catch (err) {
        console.log(err, 'Error Console');
        enqueueSnackbar(err?.data?.message, { variant: 'error', autoHideDuration: null });
        err?.data?.messages.map((item) => {
          enqueueSnackbar(item, { variant: 'error', autoHideDuration: null });
        });
        window.location.reload();
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const res = await enquireRejectQuoteService(id);
        // enqueueSnackbar(res?.message, { variant: 'success' });
        await res?.messages?.map(async(item) => {
          await enqueueSnackbar(item, { variant: 'error', autoHideDuration: null });
        });
        setConfirmBoxOpen(true);
        // window.location.reload();
      } catch (err) {
        console.log(err, 'Error Console');
        enqueueSnackbar(err?.data?.message, { variant: 'error', autoHideDuration: null });
        err?.data?.messages.map((item) => {
          enqueueSnackbar(item, { variant: 'error', autoHideDuration: null });
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const rollbackHandler = async () => {
    setLoading(true);
    try {
      const res = await rollbackQuoteService(id);
      enqueueSnackbar(res.message, { variant: 'success' });
      window.location.reload();
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
      window.location.reload();
    } finally {
      setLoading(false);
    }
  };
  return (
    <Box py={3}>
      {disabled === true ? (
        <Button
          sx={{ width: 120 }}
          variant="contained"
          disabled={isLoading}
          onClick={rollbackHandler}
          startIcon={isLoading ? <CircularProgress size={20} /> : null}
        >
          {isLoading ? 'Rolling Back...' : 'Roll Back'}
        </Button>
      ) : (
        <Button
          sx={{ width: 120 }}
          variant="contained"
          disabled={isLoading || disabled}
          onClick={()=>onCancel(false)}
          startIcon={isLoading ? <CircularProgress size={20} /> : null}
        >
          {isLoading ? 'Rolling Back...' : 'Roll Back'}
        </Button>
      )}
      <ConfirmBox 
        open={confirmBoxOpen}
        title="Confirm Action"
        content="Do you want to perform this action forcefully ?"
        onClose={()=>setConfirmBoxOpen(false)}
        onConfirm={()=>handleConfirm()}/>
    </Box>
  );
};

export default CancelButton;
