import axios, { endpoints } from 'src/utils/axios';

export const getQuotePdfDetails = (type) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${endpoints.pdfGenerate.getpdfDetails}${type}`)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });

export const generateQuotePdf = (id, data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.pdfGenerate.generateQuotePdf}${id}`, data, {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        // const blob = new Blob([response.data], { type: 'application/pdf' });
        // const url = window.URL.createObjectURL(blob);
        // const a = document.createElement('a');
        // a.href = url;
        // // a.download = 'quote.pdf';
        // a.click();
        // window.URL.revokeObjectURL(url);
        resolve(response);
      })
      .catch((err) => reject(err));
  });

export const generateProformaPdf = (id, data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.pdfGenerate.generateProformaPdf}${id}`, data, {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        // const blob = new Blob([response.data], { type: 'application/pdf' });
        // const url = window.URL.createObjectURL(blob);
        // const a = document.createElement('a');
        // a.href = url;
        // a.download = 'proformaInvoice.pdf';
        // a.click();
        // window.URL.revokeObjectURL(url);
        resolve(response);
      })
      .catch((err) => reject(err));
  });

  export const generateClientLedgerPdf = (id) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.pdfGenerate.generateClientLedgerPdf}${id}`, {}, {
        responseType: 'blob', // Keep this to handle PDF blob
      })
      .then(response => {
        // Check if the response is a PDF blob
        if (response.headers['content-type'] === 'application/pdf') {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const date = new Date();
          const day = date.getDate();
          const month = date.getMonth() + 1;
          const year = date.getFullYear();
          const formattedDate = `${day}/${month}/${year}`;
          
          // Create a link element for downloading the PDF
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob); 
          link.download = `client-ledger-${formattedDate}.pdf`; 
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(link.href);
          
          resolve();
        } else {
          // Handle case where response is not a PDF (assumed to be text)
          return response.data.text().then(text => {
            console.error('Error message from server:', text);
            reject(text); // Reject with the error message
          });
        }
      })
      .catch(error => {
        // Handle network errors or other issues
        if (error) {
          const errorBlob = error.data;
          const reader = new FileReader();
          
          reader.onload = () => {
            const errorMessage = JSON.parse(reader.result); // Extract error message from blob
            console.log('Error downloading the PDF:', errorMessage);
            reject(errorMessage); // Reject with the error message
          };

          reader.readAsText(errorBlob); // Read the blob as text to extract the message
        } else {
          console.log('Error downloading the PDF:', error.message);
          reject(error.message); // Fallback for network errors or other issues
        }
      });
  });

export const generateCommercialPdf = (id, data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.pdfGenerate.generateCommercialPdf}${id}`, data, {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${data?.fileName}.pdf`;
        a.click();
        window.URL.revokeObjectURL(url);
        resolve(response);
      })
      .catch((err) => reject(err));
  });

  export const generateBinderPdf = (id,comId) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.pdfGenerate.generateBinderPdf}/${id}/${comId}`,{},
      {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `BinderFile-${id}-${comId}.pdf`;
        a.click();
        window.URL.revokeObjectURL(url);
        resolve(response);
      })
      .catch((err) => reject(err));
  });
