import axios, { endpoints } from 'src/utils/axios';

export const getAllCurrency = () =>
  new Promise((resolve, reject) => {
    axios
      .get(endpoints.common.getAllCurrency)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const addNewCurrency = (data) =>
  new Promise((resolve, reject) => {
    axios
      .post(endpoints.common.addNewCurrency, data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const deleteCurrency = (currencyId) =>
  new Promise((resolve, reject) => {
    axios
      .delete(`${endpoints.common.deleteCurrency}${currencyId}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const LineItemMoveDownApi = (quoteId, lineItemId) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.common.lineItemMoveDown}${quoteId}/${lineItemId}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const LineItemMoveUpApi = (quoteId, lineItemId) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.common.lineItemMoveUp}${quoteId}/${lineItemId}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const updateWarrantyApi = (quoteId, lineItemId, warranty) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.common.updateWarranty}${quoteId}/${lineItemId}`, { warranty })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const convertCurrency = (currency_value, source_currency, destination_currency) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.common.convertCurrency}`, {
        currency_value,
        source_currency,
        destination_currency,
      })
      .then((response) => resolve(response.data.convertedValue))
      .catch((error) => reject(error));
  });

export const UPDATE_CURRENCY = (currency, value) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.common.updateCurrency}`, {
        currency,
        value,
      })
      .then((response) => resolve(response.data.convertedValue))
      .catch((error) => reject(error));
  });

export const CancelItem = (id, type) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.common.cancelItem}/${id}/${type}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
