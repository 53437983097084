// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useGridApiContext } from '@mui/x-data-grid';
import { Button, MenuItem, Select, Typography } from '@mui/material';
import { North, South } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { LineItemMoveDownApi, LineItemMoveUpApi } from 'src/services/common';
import { useParams } from 'src/routes/hooks';
// ----------------------------------------------------------------------

function CustomEditComponent(props) {
  const paramData = useParams();
  const { id,row } = props;
  const [loading, setLoading] = useState(false);

  const MoveDown = () => {
    setLoading(true);
    LineItemMoveDownApi(paramData.id,id)
      .then(() => {
        row?.setRefresh(row?.refresh+1);
        row.change = true;
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        enqueueSnackbar('Error! Please try again');
      });
  };
  const MoveUp = () => {
    setLoading(true);
    LineItemMoveUpApi(paramData.id,id)
      .then(() => {
        row?.setRefresh(row?.refresh+1)
        row.change = true;
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        enqueueSnackbar('Error! Please try again');
      });
  };



  return (
    <Box mx={2} my={2}>
      <Stack spacing={2}>
        {row?.index ===0?"":
      <LoadingButton loading={loading} onClick={()=>MoveUp()}>  <North/><Typography variant="caption">MOVE UP</Typography>  </LoadingButton>}
      {(row?.index+1) === row?.length ?null:
      <LoadingButton loading={loading} onClick={()=>MoveDown()}>  <South/><Typography variant="caption">MOVE DOWN</Typography>  </LoadingButton>}
      {row?.length ===1 ? <Typography>No Action</Typography>:null}
      </Stack>
    </Box>
  );
}

function CustomViewComponent(props) {
  const { value } = props;
  return (
    <Box mx={2} my={2}>
      <Stack spacing={2}>
      <Button> Move Up  <North/> </Button>
      <Button>Move Down  <South/> </Button>
      </Stack>
    </Box>
  );
}

export default {
  field: 'Action',
  headerName: 'ACTION',
  width: 180,
  editable: true,

  renderEditCell: (params) => <CustomEditComponent {...params} />,
  renderCell: (params) => <CustomEditComponent {...params} />,
};
