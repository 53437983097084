import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Box,
} from '@mui/material';
import React, { useState } from 'react';

export default function CommentDialog(props) {
  const { onClickSubmit, loading } = props || {};
  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState('');
  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Comment</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            multiline
            minRows={3}
            variant="outlined"
            id="name"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            label="Comment"
            sx={{ width: 300 }}
          />
        </DialogContent>
        <DialogActions>
          <Button
                    style={{ textTransform: 'uppercase' }} onClick={() => setOpen(false)}>Cancel</Button>
          <LoadingButton style={{textTransform:"uppercase"}}
            loading={loading}
            onClick={() => {
              setOpen(false);
              onClickSubmit(comment);
            }}
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Box onClick={() => setOpen(true)}>{props.children}</Box>
    </>
  );
}
