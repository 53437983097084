import React, { useEffect, useState } from 'react';
import { Box, Button, Chip, Container, Typography, alpha } from '@mui/material';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import { primaryPresets } from 'src/theme/options/presets';
import { CurrencyPicker } from 'src/components/currency-picker/currency-picker';
import { useParams, useRouter } from 'src/routes/hooks';
// import VendorQuoteOrdersTable from '../vendorQuoteOrdersTable';
import { getAllVendorApprovedRequests } from 'src/services/vendorDashboard';
import VendorQuoteOrdersCommonTable from '../commonTable';
import VendorQuotesNav from 'src/components/Navs/VendorQuotesNav';
import { useOutletContext } from 'react-router';
export default function VendorQuoteOrdersView() {
  const settings = useSettingsContext();
  const router = useRouter();
  const { id, partId,vid } = useParams();
  const [data] = useOutletContext();
  const [requestData, setRequestData] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [currency, setCurrency] = useState({});

  useEffect(() => {
    getAllVendorApprovedRequests(id, partId,vid).then((vdata) => {
      setRequestData(vdata);
      setCurrency(vdata?.[0]?.quote?.currency);
    });
  }, [refresh]);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      {vid?
      <CustomBreadcrumbs
        heading="Vendor Quote Order"
        links={[
          { name: 'Home', href: '/' },
          {
            name: 'Vendor',
            href: `/quote/${id}/vendor`,
          },
          {
            name: 'Vendor Quote Orders',
            href: '/vendor-quote/vendor-quote-orders',
          },
        ]}
        sx={{ mb: { xs: 3, md: 5 } }}
      />:<CustomBreadcrumbs
      heading="Vendor Quote Order"
      links={[
        { name: 'Home', href: '/' },
        {
          name: 'Vendor Quote Orders',
          href: '/vendor-quote/vendor-quote-orders',
        },
      ]}
      sx={{ mb: { xs: 3, md: 5 } }}
    />}
      <Box display="flex" justifyContent="space-between">
        <VendorQuotesNav id={id} url={`/${id}/${partId}`} vid={vid} currentTab={"order"}/>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          width: 1,
          paddingLeft: 4,
          paddingRight: 4,
          paddingTop: 3,
          paddingBottom: 3,
          borderRadius: 2,
          mt: 2,
          bgcolor: (theme) => alpha(theme.palette.grey[500], 0.04),
          border: (theme) => `solid 1px ${theme.palette.divider}`,
        }}
      >
        <Box>
          {/* <Chip style={{textTransform:"uppercase"}} sx={{ borderRadius: 0, mr: 1 }} label="Project ID: 1087" /> */}
          <Chip
            style={{ borderRadius: 1, marginRight: 10, textTransform: 'uppercase', color: 'white' }}
            label={`Project ID: ${data.projectId}`}
            variant="outlined"
          />
          <Chip
            style={{ borderRadius: 1, textTransform: 'uppercase', color:'white' }}
            label={`RFQ: ${data.RFQ}`}
            variant="outlined"
          />
          <Chip
            style={{ borderRadius: 1, textTransform: 'uppercase', color:'white' }}
            label={`RFQ NAME: ${data.RFQName}`}
            variant="outlined"
          />
        </Box>
        <Typography textTransform="uppercase" variant="caption">
          {'Recently edited by - {User Name}'}
        </Typography>
      </Box>

      <VendorQuoteOrdersCommonTable requestData={requestData} currency={currency} refresh={refresh} setRefresh={setRefresh} />
    </Container>
  );
}
