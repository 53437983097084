import {
  Box,
  Button,
  Chip,
  Container,
  FormControl,
  Input,
  TextField,
  InputLabel,
  Typography,
  Grid,
  alpha,
  Stack,
  CircularProgress,
} from '@mui/material';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import { primaryPresets } from 'src/theme/options/presets';
import PurchaseOrderClientTable from 'src/sections/backendView/purchaseOrderClient/purchaseOrderClientTable';
import { ArrowDropDown, Search } from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';
import { getQuotedetails, updatePOsignedFile } from 'src/services/purchaseOrder';
import { useParams } from 'src/routes/hooks';
import { LoadingButton } from '@mui/lab';
import UploadFile from 'src/components/upload/uploadFileButton';
import ContractAmountDisplay from 'src/components/common/ContractAmountDisplay';
import CommonNavigation from 'src/components/custom/CommonNavigation';
import { useSnackbar } from 'src/components/snackbar';
import { HasAccess } from '@permify/react-role';
import { SetApproval, updateQuotes } from 'src/services/quotes';
import CancelButton from 'src/components/common/CancelButton';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import HistoryIcon from '@mui/icons-material/History';
import { useAuthContext } from 'src/auth/hooks';
export default function PurchaseOrderL1Section() {
  const { id } = useParams();
  const fileInputRef = React.createRef();
  const settings = useSettingsContext();
  const [data, setData] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedFile, setSelectedFile] = useState(null);
  const [refresh, setRefresh] = useState(0);
  const [loading, setLoading] = useState(false);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  useEffect(() => {
    const GetDataFromApi = async () => {
      const response = await getQuotedetails(id);
      await setData(response);
    };
    GetDataFromApi();
  }, [id, refresh]);
  // const { user } = useAuthContext();
  const newDate = data?.AcceptedPODate;
  const dateObject = new Date(newDate);
  var formattedDate = dateObject.toLocaleDateString();

  const isCurrentPage = (href) => {
    return window.location.pathname === href;
  };

  const approvePO = () => {
    setLoading(true);
    SetApproval(id, {
      item: 'isPOApproved',
      value: true,
    })
      .then(() => {
        setLoading(false);
        setData({ ...data, isPOApproved: true });
        enqueueSnackbar('Quote approved');
      })
      .catch(() => {
        setLoading(false);
        enqueueSnackbar('Error! Please try again');
      });
  };


  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <Box
        sx={{
          display: 'flex',
          color: 'white',
        }}
      >
        <Box
          sx={{
            flex: '1',
            borderRight: '2px solid white',
            padding: '10px',
            maxWidth: '25%',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ fontSize: '22px' }}>PURCHASE ORDER</div>
          </div>
          <CustomBreadcrumbs
            links={[
              { name: 'Home', href: '/home' },
              {
                name: 'PURCHASE ORDER LIST',
                href: '/home/poStage',
              },
            ]}
            sx={{ mb: { xs: 3, md: 5 } }}
          />
        </Box>
        <Box
          sx={{
            flex: '2',
            padding: '10px',
          }}
        >
          <div style={{ display: 'flex' }}>
            <div style={{ flex: '80%' }}>
            <Box
                sx={{
                  display: 'flex',
                }}
              >
                <Box
                  sx={{
                    flex: '15%',
                    padding: '5px',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box>PROJECT ID</Box>
                    <Box>:</Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    flex: '70%',
                    padding: '5px',
                  }}
                >
                  {data?.projectId}
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                }}
              >
                <Box
                  sx={{
                    flex: '15%',
                    padding: '5px',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box>PO NUMBER</Box>
                    <Box>:</Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    flex: '70%',
                    padding: '5px',
                  }}
                >
                  {data?.clientPO}
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                }}
              >
                <Box
                  sx={{
                    flex: '15%',
                    padding: '5px',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box>RFQ </Box>
                    <Box>:</Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    flex: '70%',
                    padding: '5px',
                  }}
                >
                  {data?.RFQ}
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                }}
              >
                <Box
                  sx={{
                    flex: '15%',
                    padding: '5px',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box>RFQ NAME</Box>
                    <Box>:</Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    flex: '70%',
                    padding: '5px',
                  }}
                >
                  {data?.RFQName}
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                }}
              >
                <Box
                  sx={{
                    flex: '15%',
                    padding: '5px',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box>ACCEPTANCE DATE</Box>
                    <Box>:</Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    flex: '70%',
                    padding: '5px',
                  }}
                >
                  {moment(data?.AcceptedPODate).format('DD-MM-YYYY')}
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                }}
              >
                <Box
                  sx={{
                    flex: '15%',
                    padding: '5px',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box>ECCD FILE</Box>
                    <Box>:</Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    flex: '70%',
                    padding: '5px',
                  }}
                >
                  {data?.ECCD ? 
                   <UploadFile
                          label="ECCD File"
                          path={`test/quote/${data?._id}/po/ECCD`}
                          value={data?.ECCD}
                          disabled={true}
                        />: "NOT UPLOADED"}
                </Box>
              </Box>
            </div>
          </div>
        </Box>
      </Box>
      <PurchaseOrderClientTable
        lineItems={data?.line_items}
        data={data}
        setRefresh={setRefresh}
        refresh={refresh}
      />
    </Container>
  );
}
