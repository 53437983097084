import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useEffect, useState } from 'react';
import { getAllCurrency } from 'src/services/common';

function CurrencyPicker() {
  const [currencyListing, setCurrencyListing] = useState([]);
  const [currency, setCurrency] = useState('USD');

  const onChangeCurrency = (e) => {
    setCurrency(e.target.value);
  };

  useEffect(() => {
    getAllCurrency().then((response) => {
      setCurrencyListing(response);
    });
  }, []);
  // console.log(currency);
  return (
    <FormControl style={{ width: 200 }}>
      <InputLabel>Currency</InputLabel>
      <Select size="small" label="Currency" value={currency} onChange={onChangeCurrency}>
        {currencyListing?.map((currencyVal) => (
          <MenuItem key={currencyVal?.abbreviation} value={currencyVal?.abbreviation}>
            {`${currencyVal?.abbreviation} (${currencyVal?.symbol})`}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export { CurrencyPicker };
