import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// routes
import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';
import { useRouter } from 'src/routes/hooks';
// auth
import { useAuthContext } from 'src/auth/hooks';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { useBoolean } from 'src/hooks/use-boolean';
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import Iconify from 'src/components/iconify/iconify';
import { getCountries, getRegions } from 'src/services/vendor';

// ----------------------------------------------------------------------

export default function VendorRegistrationView() {
  const { vendorRegister } = useAuthContext();

  const router = useRouter();

  const [errorMsg, setErrorMsg] = useState('');

  const phoneRegExp = /^[0-9]{10}$/;

  const [formData, setFormData] = useState({});
  const password = useBoolean();
  const [countries, setCountries] = useState([]);
  const [regions, setRegions] = useState([]);

  useEffect(() => {
    getCountries().then((response) => {
      setCountries(response?.country);
    });
  }, []);

  useEffect(() => {
    if (formData?.country) {
      getRegions(formData?.country).then((response) => {
        setRegions(response?.states);
      });
    }
  }, [formData?.country]);

  const RegisterSchema = Yup.object().shape({
    vendorName: Yup.string().required('Vendor name required'),
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    address: Yup.string().required('Address is required'),
    phoneNumber: Yup.string().matches(phoneRegExp, 'Please enter valid phone number'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    vendorName: '',
    email: '',
    address: '',
    phoneNumber: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    if (!formData?.country) {
      setErrorMsg('Please select country');
      return;
    }
    if (!formData?.region) {
      setErrorMsg('Please select region');
      return;
    }
    if (!formData?.specialization) {
      setErrorMsg('Please select specialization');
      return;
    }

    try {
      await vendorRegister?.(
        data?.email,
        data?.password,
        data?.vendorName,
        data?.phoneNumber,
        data?.address,
        formData?.country,
        formData?.region,
        formData?.specialization
      );

      router.push(paths.auth.vendor.login);
    } catch (error) {
      reset();
      setErrorMsg(error?.data?.error ?? '');
    }
  });

  const onChangeText = (field, val) => {
    setFormData({ ...formData, [field]: val.target.value });
  };

  const renderHead = (
    <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
      <Typography textTransform="uppercase" variant="h4">Get started absolutely free</Typography>

      <Stack direction="row" spacing={0.5}>
        <Typography textTransform="uppercase" variant="body2"> Already have an account? </Typography>

        <Link href={paths.auth.vendor.login} component={RouterLink} variant="subtitle2">
          Sign in
        </Link>
      </Stack>
    </Stack>
  );

  const renderTerms = (
    <Typography textTransform="uppercase"
      component="div"
      sx={{
        color: 'text.secondary',
        mt: 2.5,
        typography: 'caption',
        textAlign: 'center',
      }}
    >
      {'By signing up, I agree to '}
      <Link underline="always" color="text.primary">
        Terms of Service
      </Link>
      {' and '}
      <Link underline="always" color="text.primary">
        Privacy Policy
      </Link>
      .
    </Typography>
  );

  const renderForm = (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Stack spacing={2.5}>
        {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}

        <RHFTextField sx={{textTransform:"uppercase"}} name="vendorName" label="Vendor name" />

        <RHFTextField sx={{textTransform:"uppercase"}} name="email" label="Vendor Email address" />
        <RHFTextField sx={{textTransform:"uppercase"}} multiline name="address" label="Vendor Address" />
        <RHFTextField sx={{textTransform:"uppercase"}} type="number" name="phoneNumber" label="Vendor Phone Number" />
        <RHFTextField sx={{textTransform:"uppercase"}}
          name="password"
          label="Password"
          type={password.value ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={password.onToggle} edge="end">
                  <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <FormControl fullWidth>
          <InputLabel>Country</InputLabel>
          <Select
            name="country"
            label="Country"
            value={formData?.country}
            onChange={(e) => {
              onChangeText('country', e);
              setRegions([]);
            }}
          >
            {countries?.map((country) => (
              <MenuItem value={country}>{country}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth disabled={!formData?.country}>
          <InputLabel>Region</InputLabel>
          <Select
            name="region"
            label="Region"
            value={formData?.region}
            onChange={(e) => onChangeText('region', e)}
          >
            {regions?.map((region) => (
              <MenuItem value={region}>{region}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth disabled={!formData?.region}>
          <InputLabel>Specialization</InputLabel>
          <Select
            name="Specialization"
            label="Specialization"
            value={formData?.specialization}
            onChange={(e) => onChangeText('specialization', e)}
          >
            <MenuItem value="A">A</MenuItem>
            <MenuItem value="B">B</MenuItem>
            <MenuItem value="C">C</MenuItem>
            <MenuItem value="D">D</MenuItem>
          </Select>
        </FormControl>

        <LoadingButton style={{textTransform:"uppercase"}}
          fullWidth
          color="inherit"
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Create account
        </LoadingButton>
      </Stack>
    </FormProvider>
  );

  return (
    <>
      {renderHead}

      {renderForm}

      {renderTerms}
    </>
  );
}
