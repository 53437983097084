import { useState, memo, useCallback, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import {
  Button,
  InputAdornment,
  Link,
  Paper,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { useAuthContext } from 'src/auth/hooks';
import { convertCurrency } from 'src/services/common';
import Grid from '@mui/material/Unstable_Grid2';

// ----------------------------------------------------------------------

function CurrencyConverter({
  title,
  fieldName,
  id,
  readonly,
  targetCurrency,
  targetValue,
  handleUpdateValue,
  index,
  sourceCurrency,
  sourceValue,
  handleUpdateCurrencyPriceValue
}) {
  const [currencyListing, setCurrencyListing] = useState([]);
  const [sourcedCurrency, setSourcedCurrency] = useState(sourceCurrency);
  const [sourcedPrice, setSourcedPrice] = useState(sourceValue);
  const [targetedPrice, setTargetedPrice] = useState();
  const [targetedCurrency, setTargetedCurrency] = useState(targetCurrency);
  const { currency } = useAuthContext();
  useEffect(() => {
      setCurrencyListing(currency);
  }, [currency]);

  useEffect(() => {
    setTargetedPrice(targetValue);
  }, [targetValue]);
  useEffect(() => {
    if(targetedCurrency){
      onChangeCurrency();
      getCurrObjByAbbreviation(targetedCurrency)
    }
  }, [targetedCurrency]);

  useEffect(() => {
    if(targetedCurrency){
      onChangeCurrency();
    }
  }, [sourcedCurrency]);
  //setting up source price whenever we have target price by default
//   useEffect(() => {
//     if(sourceCurrency){
//       setSourcedCurrency(sourceCurrency)
//     }
//   }, [sourceCurrency]);

  async function getCurrObjByAbbreviation(id,price) {
    let field = "currency"
    console.log('here currency called',id)
    const item = await currencyListing.find(entry => entry._id === id);
    if(item){handleUpdateCurrencyPriceValue(item, price)}
  }

  async function getAbbreviationById(id) {
    const item = await currencyListing.find(entry => entry._id === id);
    console.log(item,"ashuashuh")
    return item && item?.abbreviation ? item?.abbreviation : null;
  }
  const onChangeCurrency = async (e) => {
    let sourcedAbb = await getAbbreviationById(sourcedCurrency)
      let targetAbb = await getAbbreviationById(targetedCurrency)
    if (sourcedPrice && sourcedAbb && targetAbb) {
      
      let targetVal
      console.log(sourcedPrice, sourcedCurrency, targetedCurrency,"hello ashu")
      await convertCurrency(sourcedPrice, sourcedAbb, targetAbb).then((res) => {
        targetVal = parseFloat(res.toFixed(3));
      });
      setTargetedPrice(targetVal);
    //   setSourcedCurrency(e.target.value);
    //   handleUpdateValue(fieldName, targetVal, id, index, sourcedPrice, sourcedCurrency);
      getCurrObjByAbbreviation(targetedCurrency,targetVal);
    } else {
    //   setSourcedCurrency(e.target.value);
    //   handleUpdateValue(fieldName, targetedPrice, id, index, sourcedPrice, sourcedCurrency)
      getCurrObjByAbbreviation(targetedCurrency,targetedPrice);
    }
  };

  const handleBlur = async (e) => {
    let sourcedAbb = await getAbbreviationById(sourcedCurrency)
    let targetAbb = await getAbbreviationById(targetedCurrency)
    if (sourcedPrice && sourcedAbb && targetAbb){

    //   let sourcedAbb = await getAbbreviationById(sourcedCurrency)
      let targetVal
      await convertCurrency(sourcedPrice,sourcedAbb, targetAbb).then((res) => {
        targetVal = parseFloat(res.toFixed(3));
      });
      setTargetedPrice(targetVal);
    //   handleUpdateValue(fieldName, targetVal, id, index, sourcedPrice, sourcedCurrency);
      getCurrObjByAbbreviation(targetedCurrency, targetVal);
    }
  };

  return (
    <Stack spacing={1}>
      <Typography textTransform="uppercase" sx={{mt:1}}>{title ? title + ':' : ''}</Typography>
      <Grid container spacing={2}>
        <Grid item>
          <TextField
            variant="outlined"
            type="number"
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
            }}
            sx={{ maxWidth: 100, height: '100%' }}
            disabled={readonly}
            value={sourcedPrice}
            //onChange={(e) => {handleTextFieldChange('eshopPrice', e, row?._id); setSourcedPrice(e.target.value)}}
            onChange={(e) => {
              setSourcedPrice(e.target.value);
            }}
            onBlur={handleBlur}
          />
        </Grid>

        <Grid item>
          <Select
            disabled={readonly}
            sx={{ maxWidth: 150, height: '82%' }}
            size="small"
            label="Currency"
            defaultValue={sourceCurrency}
            value={sourcedCurrency}
            onChange={(e)=> setSourcedCurrency(e.target.value)}
          >
            {currencyListing?.map((currency) => (
              <MenuItem key={currency?.abbreviation} value={currency?._id}>
                {`${currency?.abbreviation} (${currency?.symbol})`}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item>
          <TextField
            variant="outlined"
            type="number"
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
            }}
            sx={{ maxWidth: 100, height: '100%' }}
            disabled={true}
            value={targetedPrice}
            // onChange={(e) => handleUpdateValue(fieldName, e, id)}
          />
        </Grid>

        <Grid item>
          <Select
            sx={{ maxWidth: 150, height: '82%' }}
            size="small"
            label="Currency"
            defaultValue={targetedCurrency}
            value={targetedCurrency}
            // disabled
            onChange={(e)=> setTargetedCurrency(e.target.value)}
          >
            {currencyListing?.map((currency) => (
              <MenuItem key={currency?.abbreviation} value={currency?._id}>
                {`${currency?.abbreviation} (${currency?.symbol})`}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default memo(CurrencyConverter);
