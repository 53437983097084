/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  Box,
  Button,
  Chip,
  Collapse,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { useState, useEffect, useMemo } from 'react';
import Iconify from 'src/components/iconify/iconify';
import Scrollbar from 'src/components/scrollbar';
import { TablePaginationCustom, useTable } from 'src/components/table';
import AddMoreDialog from './addMoreDialog';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { RHFMultiSelect, RHFSelect, RHFTextField } from 'src/components/hook-form';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { quoteRequest } from 'src/services/vendorDashboard';
import { addDeliveryDetailsVendor } from 'src/services/delivery';
import UploadMultipleFIles from 'src/components/upload/UploadMutipleFIlesButton';
import UploadFileButton from 'src/components/upload/uploadFileButton';
import UploadMultipleDocs from 'src/components/upload/UploadMultipleDocs';
import GetTrackingStatus from 'src/components/custom/GetTrackingStatus';
import PriceSelectV2 from 'src/components/custom/PriceSelectV2';
import InputAdornment from '@mui/material/InputAdornment';
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const imageInstructions = [
  'Picture of Product Upload Image Placeholder.',
  'Keep it Auto-resize fit for each item.',
  'So vendor can upload actual image for reference.',
  'Same in Level 2 also can be used from Vendor panel database',
];

export default function VendorQuoteOrdersTable({ requestData, currency, refresh, setRefresh }) {
  const table = useTable({ defaultRowsPerPage: 10 });
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [tabeleData, setTabeleData] = useState([]);
  const [file, setFile] = useState('');
  const [packageDimension, setPackageDimension] = useState([
    { label: 'L', value: '' },
    { label: 'W', value: '' },
    { label: 'H', value: '' },
    { label: 'Wt.', value: '' },
  ]);
  const [showDialog, setShowDialog] = useState(false);

  const COLUMNS = [
    { id: 'slNo', label: 'SNO.', minWidth: 50, align: 'center' },
    {
      id: 'PN',
      label: 'PN',
      minWidth: 170,
    },
    {
      id: 'desc',
      label: 'Description',
      minWidth: 220,
      render: (val, row) => <Box>{row.description}</Box>,
    },

    {
      id: 'quantityApproved',
      label: 'QUANTITY',
      minWidth: 170,
    },

    {
      id: 'isArchived',
      label: 'Archive',
      minWidth: 100,
    render: (value) => <Chip sx={{color:'black', backgroundColor:'white'}} label={value ? 'Yes' : 'No'}></Chip>,
    },
  ];

  const COLUMNS2 = [
    {
      id: 'quantity',
      label: 'Quantity',
      minWidth: 150,
      render: (values) => <RHFTextField name="quantity" label="QTY" />,
    },
    {
      id: 'initiatedBy',
      label: 'Initiated By',
      minWidth: 150,
      render: () => (
        <RHFSelect
          name="initiatedBy"
          label="Initiated By"
          options={[
            { label: 'Vendor', value: 'vendor' },
            { label: 'Workbiz', value: 'workbiz' },
          ]}
        >
          <MenuItem value="vendor">Vendor</MenuItem>
          <MenuItem value="workbiz">Workbiz</MenuItem>
        </RHFSelect>
      ),
    },
    {
      id: 'qualityPics',
      label: 'Quality Pictures',
      minWidth: 300,
      render: (values, error, setValue) => (
        <UploadMultipleFIles
          name="qualityPics"
          label="Quality Pictures"
          path="deliveryPictured"
          value={values.qualityPics}
          setValue={(val) => setValue('qualityPics', val)}
        />
      ),
    },
    {
      id: 'packageDimension',
      label: 'Package Dimension',
      minWidth: 170,
      render: (v) => (
        <Stack spacing={1}>
          <RHFTextField
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">CM</InputAdornment>,
            }}
            name="dimentions.length"
            label="Length"
          />
          <RHFTextField
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">CM</InputAdornment>,
            }}
            name="dimentions.width"
            label="Width"
          />
          <RHFTextField
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">CM</InputAdornment>,
            }}
            name="dimentions.height"
            label="Height"
          />
          <RHFTextField
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">kg</InputAdornment>,
            }}
            name="dimentions.weight"
            label="Weight"
          />
          {/* <Button
              startIcon={<Iconify width={20} icon="carbon:add" />}
              variant="contained"
              sx={{ mb: 1.2, mt: 0.8 }}
              onClick={() => setShowDialog(true)}
            >
              <Typography textTransform="uppercase" variant="button">Add more</Typography>
            </Button>
            <Button variant="text" startIcon={<Iconify width={15} icon="carbon:checkbox" />}>
              <Typography textTransform="uppercase" variant="caption">Same as above</Typography>
            </Button> */}
        </Stack>
      ),
    },
    // {
    //   id: 'downloadDocuments',
    //   label: 'Download Documents',
    //   minWidth: 150,
    //   render: () => (
    //     <FormControl fullWidth>
    //       <InputLabel>Documents</InputLabel>
    //       <Select
    //         label="Documents"
    //         value={formData?.fillCurrencyDropdown}
    //         onChange={(e) => onChangeText('fillCurrencyDropdown', e)}
    //       >
    //         <MenuItem value="A">DHL</MenuItem>
    //         <MenuItem value="B">BLUE DART</MenuItem>
    //         <MenuItem value="C">INDIA POST</MenuItem>
    //         <MenuItem value="D">ECART</MenuItem>
    //       </Select>
    //     </FormControl>
    //   ),
    // },
    {
      id: 'estimatedShipping',
      label: 'Estimated shipping cost',
      minWidth: 170,
      align: 'center',
      render: (value, error, setValue) => (
        <>
          <Box display="flex" sx={{ minWidth: 180 }}>
            <PriceSelectV2
              value={value.estimatedCost}
              size="small"
              error={error.estimatedCost || ''}
              setCurrency={(val) => setValue('estimatedCost.currency', val)}
              setValue={(val) => setValue('estimatedCost.value', val)}
            />
          </Box>
        </>
      ),
    },
    {
      id: 'pickupConfirm',
      label: 'Pickup Confirm',
      minWidth: 100,
      render: (values, error, setValue) => (
        <Typography textTransform="uppercase" variant="button">
          <FormControl fullWidth>
            <Select
              label="Select Warranty Start Date"
              value={values.pickupConfirmation}
              onChange={(event) => setValue('pickupConfirmation', event?.target?.value)}
            >
              <MenuItem value={true}>YES</MenuItem>
              <MenuItem value={false}>NO</MenuItem>
            </Select>
          </FormControl>
        </Typography>
      ),
    },
    {
      id: 'trackingNumber',
      label: 'Tracking Number',
      minWidth: 210,
      render: () => <RHFTextField name="trackingId" label="Tracking Number" />,
    },
    {
      id: 'courierCompany',
      label: 'Courier Company',
      minWidth: 180,
      render: (values, errors, setValue) => (
        <Box display={'flex'} flexDirection={'column'}>
          <RHFTextField name="deliveryProvider" label="Courier Company" sx={{ mb: 2 }} />
          <UploadFileButton
            name="labelUploads"
            label="label"
            path="delivery/label"
            value={values.labelUploads}
            setValue={(val) => setValue('labelUploads', val)}
          />
        </Box>
      ),
    },
    {
      id: 'taxInvoice',
      label: 'Upload Documents',
      minWidth: 150,
      render: (values, errors, setValue) => (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
        >
          <UploadMultipleDocs
            label="certificates"
            path="delivery/certificates"
            value={values.certificates}
            setValue={(val) => setValue('certificates', val)}
          />

          <UploadMultipleDocs
            label="invoice"
            path="delivery/invoice"
            value={values.invoice}
            setValue={(val) => setValue('invoice', val)}
          />
          <UploadMultipleDocs
            label="Packaging list"
            path="delivery/Packaging"
            value={values.Packaging}
            setValue={(val) => setValue('Packaging', val)}
          />
          <UploadMultipleDocs
            label="MSDS"
            path="delivery/MSDS"
            value={values.MSDS}
            setValue={(val) => setValue('MSDS', val)}
          />
          <UploadMultipleDocs
            label="DG Declaration"
            path="delivery/dg"
            value={values.dg}
            setValue={(val) => setValue('dg', val)}
          />
        </Box>
      ),
    },
    {
      id: 'projectStatus',
      label: 'Project Status',
      minWidth: 100,
      render: (value) => <GetTrackingStatus trackingId={value.trackingId} />,
    },
  ];

  useEffect(() => {
    setTabeleData([
      {
        projectId: 1087,
        slNo: 1,
        itemNo: 12345,
        desc: '',
        pn: 'IWM-SW',
        qty: 5,
        performaInvoice: '',
        workUpdateProgress: '',
        archive: '',
        data: [{ qualityPictures: '' }, { qualityPictures: '' }, { qualityPictures: '' }],
      },
      {
        projectId: 1088,
        slNo: 2,
        itemNo: 12344,
        desc: '',
        pn: 'IWM-SW',
        qty: 6,
        performaInvoice: '',
        workUpdateProgress: '',
        archive: '',
        data: [{ qualityPictures: '' }, { qualityPictures: '' }],
      },
    ]);
  }, []);

  const handleFile = (field, e) => {
    if (e.target.files?.[0]) {
      setFile(URL.createObjectURL(e.target.files[0]));
      setFormData({ ...formData, [field]: e.target.files?.[0]?.name });
    }
  };

  const onSubmit = (title) => {
    const arr = [...packageDimension];

    arr.push({ label: title, value: '' });
    setPackageDimension(arr);
  };

  function Row({ row, index, refresh, setRefresh }) {
    const [open, setOpen] = useState(false);
    const [delivery, setDelivery] = useState(row?.delivery || []);
    return (
      <>
        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
          <TableCell style={{ verticalAlign: 'top', textTransform: 'uppercase' }}>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          {COLUMNS.map((column) => {
            const value = row[column.id];
            return (
              <TableCell
                style={{ verticalAlign: 'top', textTransform: 'uppercase' }}
                key={column.id}
                align={column.align}
              >
                {column.render ? column.render(value, row) : value}
              </TableCell>
            );
          })}
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
            <Collapse in={open}>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h4">Delivery Logs</Typography>
                <Typography variant="h6" gutterBottom component="div">
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      setDelivery([...delivery, {}]);
                    }}
                  >
                    Add Row +
                  </Button>
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      {COLUMNS2.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            textTransform: 'uppercase',
                            border: 1,
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                      <TableCell
                        style={{
                          position: 'sticky',
                          right: 0,

                          zIndex: 100,
                          borderLeft: '1px solid #fafafa',
                        }}
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {delivery.map((historyRow) => (
                      <CustomTableRow
                        row={historyRow}
                        COLUMNS={COLUMNS2}
                        currency={currency}
                        id={row._id}
                        refresh={refresh}
                        setRefresh={setRefresh}
                      />
                      // <TableRow key="123">
                      //   {COLUMNS2.map((column) => {
                      //     const value = historyRow[column.id];
                      //     return (
                      //       <TableCell
                      //         style={{ verticalAlign: 'top', textTransform: 'uppercase' }}
                      //         key={column.id}
                      //         align={column.align}
                      //       >
                      //         {column.render ? column.render(value, historyRow) : value}
                      //       </TableCell>
                      //     );
                      //   })}
                      // </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }

  return (
    <Box>
      <Paper elevation={1}>
        <TableContainer sx={{ overflow: 'unset' }}>
          <Scrollbar sx={{ maxHeight: 1200 }}>
            <Table stickyHeader sx={{ minWidth: 800 }}>
              <TableHead>
                <TableRow>
                  <TableCell style={{ minWidth: 100, textTransform: 'uppercase', border: 1 }}>
                    -
                  </TableCell>
                  {COLUMNS.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, textTransform: 'uppercase', border: 1 }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {requestData.map((row, index) => (
                  <Row
                    key={row.name}
                    row={{ ...row, slNo: index + 1 }}
                    index={index}
                    refresh={refresh}
                    setRefresh={setRefresh}
                  />
                ))}
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>

        <TablePaginationCustom
          count={tabeleData.length}
          page={table.page}
          rowsPerPage={table.rowsPerPage}
          onPageChange={table.onChangePage}
          onRowsPerPageChange={table.onChangeRowsPerPage}
        />
      </Paper>
      <AddMoreDialog open={showDialog} handleClose={setShowDialog} onSubmit={onSubmit} />
    </Box>
  );
}

// row with react hook form

const CustomTableRow = ({ COLUMNS, row, currency, id, refresh, setRefresh }) => {
  const { enqueueSnackbar } = useSnackbar();
  const DeliverySchema = Yup.object().shape({
    isInitiatedByVendor: Yup.boolean().default(false),
    dimentions: Yup.object().shape({
      length: Yup.number().required(),
      width: Yup.number().required(),
      height: Yup.number().required(),
      weight: Yup.number().required(),
    }),
    labelUploads: Yup.string(),
    estimatedShipping: Yup.number(),
    actualShipping: Yup.number(),
    deliveryProvider: Yup.string(),
    serviceType: Yup.string(),
    CostCurrency: Yup.string().default('650dbea43a3c8d27d7b98aa6'),
    trackingId: Yup.string().required(),
    qualityPics: Yup.array().of(Yup.string()),
    quantity: Yup.number().required(),
    comment: Yup.string(),
    status: Yup.string().default('pending').oneOf(['pending', 'approved', 'rejected']),
    pickupConfirmation: Yup.boolean().default(false),
  });
  const defaultValues = useMemo(
    () => ({
      isInitiatedByVendor: true,
      _id: row._id,
      certificates: row?.certificates || [],
      invoice: row?.invoice || [],
      Packaging: row?.Packaging || [],
      MSDS: row?.MSDS || [],
      dg: row?.dg || [],

      dimentions: {
        length: row?.dimentions?.length,
        width: row?.dimentions?.width,
        height: row?.dimentions?.height,
        weight: row?.dimentions?.weight,
      },
      initiatedBy: row?.initiatedBy,
      estimatedShipping: row?.estimatedShipping,
      actualShipping: row?.actualShipping,
      deliveryProvider: row?.deliveryProvider,
      serviceType: row?.serviceType,
      CostCurrency: '650dbea43a3c8d27d7b98aa6',
      actualCost: row?.actualCost || {
        currency: '650dbea43a3c8d27d7b98aa6',
        value: 0,
      },
      labelUploads: row?.labelUploads,
      estimatedCost: row?.estimatedCost || {
        currency: '650dbea43a3c8d27d7b98aa6',
        value: 0,
      },
      trackingId: row?.trackingId,
      qualityPics: row?.qualityPics || [],
      quantity: row?.quantity,
      comment: row?.comment,
      taxInvoice: row?.taxInvoice,
      status: 'pending',
      documents: row?.documents,
      pickupConfirmation: row?.pickupConfirmation || false,
    }),

    [row, currency]
  );

  const methods = useForm({
    resolver: yupResolver(DeliverySchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    setValue,
    handleSubmit,

    formState: { isSubmitting, errors },
  } = methods;

  const values = watch();

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      await addDeliveryDetailsVendor(id, { ...data });
      enqueueSnackbar('Order submitted ');
      setValue('isQuoteSubmitted', true);

      setRefresh(refresh + 1);
    } catch (error) {
      enqueueSnackbar('Error in creating project!', { variant: 'error' });
      console.error(error);
    }
  });
  // console.log(errors);
  return (
    <FormProvider {...methods}>
      <TableRow tabIndex={-1} key={row.code} style={{ verticalAlign: 'center', textTransform: 'uppercase', border: '1px solid grey' }}>
        {COLUMNS.map((column) => {
          const value = row[column.id];
          return (
            <TableCell key={column.id} align={column.align} style={{ textTransform: 'uppercase', border: '1px solid grey'  }}>
              {column.render ? column.render(values, errors, setValue) : value}
            </TableCell>
          );
        })}
        <TableCell
          style={{
            position: 'sticky',
            right: 0,
            background: '#fff',
            zIndex: 100,
            borderLeft: '1px solid #fafafa',
          }}
        >
          {
            <Button style={{ textTransform: 'uppercase' }} variant="outlined" onClick={onSubmit}>
              {values._id ? 'Save' : 'Submit'}
            </Button>
          }
        </TableCell>
      </TableRow>
    </FormProvider>
  );
};