import React from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useEffect, useState } from 'react';
import { getAllCurrency } from 'src/services/common';
const currencies = [
  {
    value: 'USD',
    label: '$',
  },
  {
    value: 'EUR',
    label: '€',
  },
  {
    value: 'GBP',
    label: '£',
  },
];

const PriceSelectV2 = ({ onChange, error, value, setCurrency, setValue, ...others }) => {
  const [currencyListing, setCurrencyListing] = useState([]);

  const [loading, setLoading] = useState(false);
  // console.log(value, error);
  let currency = value.currency;
  useEffect(() => {
    setLoading(true);
    getAllCurrency()
      .then((response) => {
        setCurrencyListing(response);
        //onChange(response[0]?._id, response[0]);
        // setCurrency(response?.[0]?._id);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);
  //   useEffect(() => {
  //     setCurrency(selectedValue);
  //   }, [value]);
  return (
    <TextField
      error={error}
      value={value?.value}
      onChange={(e) => setValue(e.target.value)}
      helperText={error || ''}
      label="Price"
      type="number"
      {...others}
      inputProps={{
        inputMode: 'numeric',
        pattern: '[0-9]*',
      }}
      InputProps={{
        inputMode: 'numeric',
        pattern: '[0-9]*',
        startAdornment: (
          <Select
            select
            defaultValue={currency}
            value={currency}
            onChange={(event) => {
              // Call the parent component's onChange function if provided
              if (onChange) {
                onChange(
                  event.target.value,
                  currencyListing.find((i) => i._id === event.target.value)
                );
                setCurrency(event.target.value);
              } else {
                setCurrency(event.target.value);
              }
            }}
            sx={{
              pl: 0,

              ml: 0,
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            }}
          >
            {currencyListing?.map((currencyVal) => (
              <MenuItem key={currencyVal?.abbreviation} value={currencyVal?._id}>
                {`${currencyVal?.symbol}`}
              </MenuItem>
            ))}
          </Select>
        ),
      }}
    />
  );
};

export default PriceSelectV2;
