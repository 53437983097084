import React from 'react';
import { Box, Button, Chip, Container, Typography, alpha } from '@mui/material';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import { primaryPresets } from 'src/theme/options/presets';
import { useOutletContext } from 'react-router';
import EshopOrderTable from '../eshopOrdersTable';
import { useParams } from 'src/routes/hooks';
import { ArrowDropDown } from '@mui/icons-material';

export default function EshopView() {
  const { id } = useParams();
  const settings = useSettingsContext();
  const [data, loading] = useOutletContext();

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <Box display="flex">
        <Box>
          <CustomBreadcrumbs
            heading="ESHOP"
            links={[
              { name: 'Home', href: '/home' },
              {
                name: 'Vendor',
                href: `/quote/${id}/vendor`,
              },
              {
                name: 'Vendor Selection',
                href: `/quote/${id}/vendor/vendor-selection`,
              },
              {
                name: 'ESHOP',
                href: `/quote/${id}/eshop`,
              },
              {
                name: 'ESHOP Order',
                href: '/eshop',
              },
            ]}
            sx={{ mb: { xs: 3, md: 5 } }}
          />
          <Box display="flex" justifyContent="space-between">
            <Box display={'flex'} >
              <Button
                variant="contained"
                href={`/quote/${id}/eshop`}
                sx={{
                  borderRadius: 0,
                  color:"black",
                  ml: 1,
                  width: 240,
                  fontSize: 14,
                  backgroundColor: 'grey',
                  mt:1
                }}
              >
                ESHOP
              </Button>
              <Button
                href={`/quote/${id}/eshop/eshop-orders`}
                variant="contained"
                sx={{ borderRadius: 0,width: 240,color:"black", ml: 1, fontSize: 14, backgroundColor: 'gray',borderTop: '5px solid red'}}
              >
                ORDERS
              </Button>
              <Button
                href={`/quote/${id}/eshop/eshop-archive`}
                variant="contained"
                sx={{ borderRadius: 0,width: 240,color:"black", ml: 1, fontSize: 14, backgroundColor: 'gray', mt: 1 }}
              >
                ARCHIVE
              </Button>
          </Box>
          </Box>
        </Box>
        <Box borderLeft={2} marginLeft={2} paddingLeft={2} borderColor={'white'} color={'white'}>
          <Typography
            textTransform="uppercase"
            variant="caption"
          >{`Project ID: ${data?.projectId}`}</Typography>
          <br />
          <Typography textTransform="uppercase" variant="caption">{`RFQ: ${data?.RFQ}`}</Typography>
          <br />
          <Typography textTransform="uppercase" variant="caption">{`RFQ NAME: ${data?.RFQName}`}</Typography>
          <br />
          <Typography textTransform="uppercase" variant="caption">
            {'Recently edited by - {User Name}'}
          </Typography>
        </Box>
        <Box borderLeft={2} marginLeft={2} paddingLeft={2} borderColor={'white'}>
          <Button
            href={`/quote/l1/${id}`}
            style={{ background: 'white', color: 'black', borderRadius: '20px' }}
            variant="outlined"
          >
            <Typography textTransform="uppercase" variant="button" b>
              GO TO Quote LEVEL 1
            </Typography>
            <ArrowDropDown fontSize="small" sx={{ color: 'black' }} />
          </Button>
          <br />
          <Button
            href={`/quote/l2/${id}`}
            style={{ background: 'white', color: 'black', borderRadius: '20px', marginTop: '10px' }}
            variant="outlined"
          >
            <Typography textTransform="uppercase" variant="button" b>
              GO TO Quote LEVEL 2
            </Typography>
            <ArrowDropDown fontSize="small" sx={{ color: 'black' }} />
          </Button>
        </Box>
      </Box>
      {/* <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          width: 1,
          paddingLeft: 4,
          paddingRight: 4,
          paddingTop: 3,
          paddingBottom: 3,
          borderRadius: 2,
          mt: 2,
          bgcolor: (theme) => alpha(theme.palette.grey[500], 0.04),
          border: (theme) => `solid 1px ${theme.palette.divider}`,
        }}
      >
        <Box>
          <Chip
            style={{ borderRadius: 1, marginRight: 10, textTransform:"uppercase" }}
            label={`Project ID: ${data?.projectId}`}
          />
          <Chip style={{ borderRadius: 1, textTransform:"uppercase" }} label={`RFQ: ${data?.RFQ}`} variant="outlined" />
        </Box>
        <Typography textTransform="uppercase" variant="caption">{'Recently edited by - {User Name}'}</Typography>
      </Box> */}

      <EshopOrderTable
        loading={loading}
        data={data?.line_items?.map((i) => ({ ...i, currency: data.currency }))}
      />
    </Container>
  );
}
