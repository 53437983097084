import axios, { endpoints } from 'src/utils/axios';

export const getQuoteDetails = (id) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${endpoints.vendor.getQuoteDetails}${id}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const getCountries = () =>
  new Promise((resolve, reject) => {
    axios
      .get(endpoints.vendor.getCountries)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const getRegions = (country) =>
  new Promise((resolve, reject) => {
    axios
      .get(endpoints.vendor.getRegions, { params: { country } })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const getCountriesForVendor = () =>
  new Promise((resolve, reject) => {
    axios
      .get(endpoints.vendor.getCountriesForVendor)
      .then((response) => resolve(response.data?.uniqueCountries))
      .catch((error) => reject(error));
  });

export const getRegionsForVendor = (country) =>
  new Promise((resolve, reject) => {
    axios
      .get(endpoints.vendor.getRegionsForVendor, { params: { country } })
      .then((response) => resolve(response.data?.uniqueRegions))
      .catch((error) => reject(error));
  });

export const getSpecializationForVendor = (country, region) =>
  new Promise((resolve, reject) => {
    axios
      .get(endpoints.vendor.getSpecializationForVendor, { params: { country, region } })
      .then((response) => resolve(response.data?.uniqueSpecializations))
      .catch((error) => reject(error));
  });

export const getVendorList = (country, region, specialization) =>
  new Promise((resolve, reject) => {
    axios
      .get(endpoints.vendor.getVendorList, { params: { country, region, specialization } })
      .then((response) => resolve(response.data?.vendor))
      .catch((error) => reject(error));
  });

export const createVendorRequest = async (data) => {
  try {
    await axios.post(endpoints.vendor.createVendorRequest, data);
    return { success: true, message: '' };
  } catch (err) {
    return { success: false, message: err?.data?.message };
  }
};

export const getQuotedProjectDetails = (projectId, partId) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${endpoints.vendor.getQuotedProjectDetails}${projectId}/${partId}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const approveQuote = (id, quantity, price) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.vendor.approveQuote}${id}`, { quantityApproved: quantity, price: price })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

  export const rejectQuote = (id, quantity, price) =>
    new Promise((resolve, reject) => {
      axios
        .get(`${endpoints.vendor.rejectQuote}${id}`)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });

  export const archiveVendorRequestService = (id) =>
    new Promise((resolve, reject) => {
      axios
        .post(`${endpoints.vendor.archiveVendorRequest}${id}`, {})
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
  });

  export const restoreArchiveVendorRequestService = (id) =>
    new Promise((resolve, reject) => {
      axios
        .post(`${endpoints.vendor.restoreArchivedVendRequest}${id}`, {})
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
  });

  export const getArchiveVendorRequestByProjectIdAndPartId = (projetId, partId) =>
    new Promise((resolve, reject) => {
      axios
        .get(`${endpoints.vendor.getArchivedVendorRequestByFilter}${projetId}&partId=${partId}`)
        .then((response) => resolve(response?.data?.docs))
        .catch((error) => reject(error));
  });
  