import { Helmet } from 'react-helmet-async';
import AccClientLevel2View from 'src/sections/acc-client-level1/view/accClientLevel2';
// sections

// ----------------------------------------------------------------------

export default function AccClientLevel2Page() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Accounting Client - Level 2</title>
      </Helmet>

      <AccClientLevel2View />
    </>
  );
}
