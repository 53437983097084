import { useState, memo, useCallback, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import {
  Button,
  InputAdornment,
  Link,
  Paper,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { useAuthContext } from 'src/auth/hooks';
import { convertCurrency } from 'src/services/common';
import Grid from '@mui/material/Unstable_Grid2';

// ----------------------------------------------------------------------

function CurrencyConverter({
  title,
  fieldName,
  id,
  readonly,
  targetCurrency,
  targetValue,
  handleUpdateValue,
  index,
  sourceCurrency,
  sourceValue
}) {
  const [currencyListing, setCurrencyListing] = useState([]);
  const [sourcedCurrency, setSourcedCurrency] = useState();
  const [sourcedPrice, setSourcedPrice] = useState();
  const [targetedPrice, setTargetedPrice] = useState();
  const { currency } = useAuthContext();

  useEffect(() => {
      setCurrencyListing(currency);
  }, [currency]);

  useEffect(() => {
    setTargetedPrice(targetValue);
  }, [targetValue]);
  useEffect(() => {
    if(sourceValue){
      setSourcedPrice(sourceValue)
    }
  }, [sourceValue]);

  //setting up source price whenever we have target price by default
  useEffect(() => {
    if(sourceCurrency){
      setSourcedCurrency(sourceCurrency)
    }
  }, [sourceCurrency]);

  function getAbbreviationById(id) {
    const item = currencyListing.find(entry => entry._id === id);
    return item ? item.abbreviation : null;
  }

  const onChangeCurrency = async (e) => {
    if (sourcedPrice) {
      let sourcedAbb = await getAbbreviationById(e.target.value)
      let targetVal
      await convertCurrency(sourcedPrice, sourcedAbb, targetCurrency).then((res) => {
        targetVal = parseFloat(res.toFixed(3));
      });
      setTargetedPrice(targetVal);
      setSourcedCurrency(e.target.value);
      handleUpdateValue(fieldName, targetVal, id, index, sourcedPrice, e.target.value);
    } else {
      setSourcedCurrency(e.target.value);
      handleUpdateValue(fieldName, targetedPrice, id, index, sourcedPrice, e.target.value)
    }
  };

  const handleBlur = async (e) => {
    if (sourcedPrice){
      let sourcedAbb = await getAbbreviationById(sourcedCurrency)
      let targetVal
      await convertCurrency(sourcedPrice, sourcedAbb, targetCurrency).then((res) => {
        targetVal = parseFloat(res.toFixed(3));
      });
      setTargetedPrice(targetVal);
      handleUpdateValue(fieldName, targetVal, id, index, sourcedPrice, sourcedCurrency);
    }
  };

  return (
    <Stack spacing={1}>
      <Typography textTransform="uppercase" sx={{mt:1}} style={{ color: '#000', fontWeight: 'bold' }}>{title ? title + ':' : ''}</Typography>
      <Grid container spacing={2}>
        <Grid item>
          <TextField
            variant="outlined"
            type="number"
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
            }}
            sx={{ maxWidth: 100, height: '100%' }}
            disabled={readonly}
            value={sourcedPrice}
            //onChange={(e) => {handleTextFieldChange('eshopPrice', e, row?._id); setSourcedPrice(e.target.value)}}
            onChange={(e) => {
              setSourcedPrice(e.target.value);
            }}
            onBlur={handleBlur}
          />
        </Grid>

        <Grid item>
          <Select
            disabled={readonly}
            sx={{ maxWidth: 150, height: '82%' }}
            size="small"
            label="Currency"
            defaultValue={sourceCurrency}
            value={sourcedCurrency}
            onChange={onChangeCurrency}
          >
            {currencyListing?.map((currency) => (
              <MenuItem key={currency?.abbreviation} value={currency?._id}>
                {`${currency?.abbreviation} (${currency?.symbol})`}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item>
          <TextField
            variant="outlined"
            type="number"
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
            }}
            sx={{ maxWidth: 100, height: '100%' }}
            disabled={true}
            value={targetedPrice}
            // onChange={(e) => handleUpdateValue(fieldName, e, id)}
          />
        </Grid>

        <Grid item>
          <Select
            sx={{ maxWidth: 150, height: '82%' }}
            size="small"
            label="Currency"
            defaultValue={targetCurrency}
            value={targetCurrency}
            disabled
            onChange={onChangeCurrency}
          >
            {currencyListing?.map((currency) => (
              <MenuItem key={currency?.abbreviation} value={currency?.abbreviation}>
                {`${currency?.abbreviation} (${currency?.symbol})`}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default memo(CurrencyConverter);
