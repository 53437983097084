import React from 'react';
import Highlighter from 'react-highlight-words';

const SearchHighlight = ({ searchText, searchResultText }) => {
    let stringValue;
    if (typeof searchResultText !== 'string') {
        stringValue = String(searchResultText);
    }else{
        stringValue = searchResultText
    }
    return (
        <div>
            <Highlighter
                highlightClassName="HighlightSearchText"
                searchWords={[searchText]}
                autoEscape={true}
                textToHighlight={stringValue ? stringValue : ""}
            />
        </div>
    );
};

export default SearchHighlight;